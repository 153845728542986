import React, {useEffect} from "react";
import {Alert, Button, FormGroup, FormText, Label} from "reactstrap";
import {
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from "redux-form";
import {focusFirstInvalid} from "../helpers/focusFirstInvalid";
import RenderField from "../helpers/RenderField";
import {IconSpinner} from "../Icons";
import {IAppThunkActionStates} from "../types/thunk";
import {IUser} from "../Users/types";
import {IESign, ISignFEADocRequestData, PDFTypes} from "./types";

interface IRequestOTPFormData {
  otp: string;
}
interface IRequestOTPFormErrors {
  otp?: string;
}

interface IOwnProps {
  additionalParams?: {[key: string]: string};
  cancelESign: () => void;
  openedTransaction: IESign;
  pdfType: PDFTypes;
  referenceId: string;
  requestOTP: () => Promise<void>;
  requestOTPStates: IAppThunkActionStates;
  signFEADoc: (data: ISignFEADocRequestData) => Promise<void>;
  signFEADocStates: IAppThunkActionStates;
  signingUser: IUser;
}

type IProps = IOwnProps & InjectedFormProps<IRequestOTPFormData, IOwnProps>;

const validate = (values: IRequestOTPFormData) => {
  const errors: IRequestOTPFormErrors = {};
  if (!values.otp) {
    errors.otp = "Inserisci l'OTP che hai ricevuto sul tuo cellulare";
  }

  return errors;
};

const RequestOTPForm: React.FC<IProps> = ({
  additionalParams,
  cancelESign,
  handleSubmit,
  openedTransaction,
  pdfType,
  referenceId,
  requestOTP,
  requestOTPStates,
  signFEADoc,
  signFEADocStates,
  signingUser,
}) => {
  useEffect(() => {
    requestOTP().then(() => {
      // Usiamo getElementById per colpa della limitazione di reduxForm sui ref
      document.getElementById("otp")?.focus();
    });
  }, [requestOTP]);

  const requestOTPSubmit = async (values: IRequestOTPFormData) => {
    if (!values.otp) {
      throw new SubmissionError(validate(values));
    }
    await signFEADoc({
      OTP: values.otp,
      pdfType,
      referenceId,
      transactionId: openedTransaction.transactionId[0],
      ...additionalParams,
    });
  };
  if (requestOTPStates.isPending) {
    return (
      <Alert color="info" className="mb-0">
        <IconSpinner className="icon-spin" /> Invio OTP in corso...
      </Alert>
    );
  } else if (requestOTPStates.isFail) {
    return (
      <>
        <Alert color="danger">{requestOTPStates.error}</Alert>
        <div className="text-center">
          <Button color="secondary" outline type="button" onClick={cancelESign}>
            Chiudi
          </Button>
        </div>
      </>
    );
  }

  return (
    <Form onSubmit={handleSubmit(requestOTPSubmit)}>
      <FormGroup>
        <Label for="exampleEmail">
          Firma Elettronica
          <br />
          Inserire il codice OTP ricevuto tramite SMS
        </Label>
        <Field
          id="otp"
          name="otp"
          component={RenderField}
          type="text"
          placeholder="Codice OTP monouso"
        />
        <FormText color="muted">
          <p>
            Abbiamo inviato il codice OTP a {signingUser.name}{" "}
            {signingUser.surname}
            <br />
            al seguente numero {signingUser.personalPhone}
          </p>
          <p>
            Per modificare il numero di cellulare consulta{" "}
            <a href="/user/profile">'Gestione del profilo'</a>
          </p>
        </FormText>
      </FormGroup>
      {signFEADocStates.isFail && (
        <Alert color="danger">{signFEADocStates.error}</Alert>
      )}
      <Button
        color="primary"
        outline
        type="submit"
        disabled={signFEADocStates.isPending}
      >
        {signFEADocStates.isPending && (
          <IconSpinner className="icon-spin mr-2" />
        )}
        Conferma
      </Button>{" "}
      <Button
        color="secondary"
        outline
        type="button"
        onClick={cancelESign}
        disabled={signFEADocStates.isPending}
      >
        Annulla
      </Button>
    </Form>
  );
};

export default reduxForm<IRequestOTPFormData, IOwnProps>({
  form: "requestOTPForm",
  onSubmitFail: focusFirstInvalid,
  validate,
})(RequestOTPForm);
