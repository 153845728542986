import React from "react";
import {Modal, ModalBody} from "reactstrap";
import {IAppThunkActionStates} from "../types/thunk";
import {IUser} from "../Users/types";
import InsertPhoneForm, {IInsertPhoneFormData} from "./InsertPhoneForm";
import RequestOTPForm from "./RequestOTPForm";
import {IESign, ISignFEADocRequestData, PDFTypes} from "./types";

interface IProps {
  additionalParams?: {[key: string]: string};
  addPersonalPhone: (values: IInsertPhoneFormData) => Promise<void>;
  cancelESign: () => void;
  createFEATransaction: () => Promise<void>;
  createFEATransactionStates: IAppThunkActionStates;
  isModalOpen: boolean;
  isProfileFail?: boolean;
  isProfilePending?: boolean;
  openedTransaction: IESign | undefined;
  pdfType: PDFTypes | undefined;
  referenceId: string | undefined;
  profileError?: string;
  signFEADoc: (data: ISignFEADocRequestData) => Promise<void>;
  signFEADocStates: IAppThunkActionStates;
  signingUser: IUser | null;
}

const RequestOTPModal: React.FC<IProps> = ({
  additionalParams,
  addPersonalPhone,
  cancelESign,
  createFEATransaction,
  createFEATransactionStates,
  isModalOpen,
  isProfileFail,
  isProfilePending,
  openedTransaction,
  pdfType,
  profileError,
  referenceId,
  signFEADoc,
  signFEADocStates,
  signingUser,
}) => {
  if (!signingUser) {
    return null;
  }

  return (
    <Modal isOpen={isModalOpen} toggle={cancelESign} backdrop="static" centered>
      <ModalBody>
        {signingUser.personalPhone ? (
          <RequestOTPForm
            additionalParams={additionalParams}
            cancelESign={cancelESign}
            openedTransaction={openedTransaction!}
            pdfType={pdfType!}
            referenceId={referenceId!}
            requestOTP={createFEATransaction}
            requestOTPStates={createFEATransactionStates}
            signFEADoc={signFEADoc}
            signFEADocStates={signFEADocStates}
            signingUser={signingUser!}
          />
        ) : (
          <InsertPhoneForm
            addPersonalPhone={addPersonalPhone}
            cancelESign={cancelESign}
            isProfileFail={isProfileFail}
            isProfilePending={isProfilePending}
            initialValues={{personalPhone: signingUser.businessPhone}}
            profileError={profileError}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default RequestOTPModal;
