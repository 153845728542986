import {IChooseOptionsFormErrors} from "./New/ChooseOptionsForm";
import {IRecommendationParams, IRecommendationsEntities} from "./types";
import {
  actions as userActions,
  actionTypes as userActionTypes,
} from "../User/actions";
import {actions, ActionTypes} from "./actions";

export interface IStateListUI
  extends Readonly<{
    isListPending?: boolean;
    isListSuccess?: boolean;
    isListFail?: boolean;
    listError?: string;
  }> {}
export interface IStateList
  extends Readonly<{
    items: string[];
    numItems?: number;
    params: Readonly<IRecommendationParams>;
    ui: IStateListUI;
  }> {}

export interface IStateNewUI
  extends Readonly<{
    isCreatePending?: boolean;
    isCreateSuccess?: boolean;
    isCreateFail?: boolean;
    createError?: string;

    isAsyncWarningPending?: boolean;
    isAsyncWarningSuccess?: boolean;
    isAsyncWarningFail?: boolean;
    asyncWarningError?: string;
  }> {}
export interface IStateNew
  extends Readonly<{
    chosenCap?: string;
    isChosenCapFromCap?: boolean;
    lastCreatedId?: string;
    warn?: Partial<IChooseOptionsFormErrors>;
    ui: IStateNewUI;
  }> {}

export type UpdatePerTypeTypes = "Accept" | "Reject";

export interface IStateViewUI
  extends Readonly<{
    isReadFail?: boolean;
    isReadPending?: boolean;
    isReadSuccess?: boolean;
    readError?: string;
    isUpdateFail?: boolean;
    isUpdatePending?: boolean;
    isUpdateSuccess?: boolean;
    updateError?: string;
    isUpdateFailPerType: {[type in UpdatePerTypeTypes]?: boolean};
    isUpdatePendingPerType: {[type in UpdatePerTypeTypes]?: boolean};
    isUpdateSuccessPerType: {[type in UpdatePerTypeTypes]?: boolean};
    updateErrorPerType: {[type in UpdatePerTypeTypes]?: string};
  }> {}
export interface IStateView
  extends Readonly<{
    ui: IStateViewUI;
  }> {}

export interface IState
  extends Readonly<{
    entities: Readonly<IRecommendationsEntities>;
    list: IStateList;
    new: IStateNew;
    view: IStateView;
  }> {}

const capsInitialState = {
  entities: {},
  list: {items: [], params: {limit: 50, offset: 0, sort: "-date"}, ui: {}},
  new: {ui: {}},
  view: {
    ui: {
      isUpdateFailPerType: {},
      isUpdatePendingPerType: {},
      isUpdateSuccessPerType: {},
      updateErrorPerType: {},
    },
  },
};

export const reducer = (
  state: IState = capsInitialState,
  action: actions | userActions
): IState => {
  // console.log({action}); // tslint:disable-line
  switch (action.type) {
    case userActionTypes.LOGOUT_SUCCESS:
      return {
        ...capsInitialState,
      };

    case ActionTypes.MERGE_ITEMS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.recommendations,
        },
      };

    case ActionTypes.CHOOSE_CAP:
      return {
        ...state,
        new: {
          ...state.new,
          chosenCap:
            action.payload.cap !== null ? action.payload.cap : undefined,
        },
      };

    case ActionTypes.CHOOSE_CAP_FROM_CAP:
      return {
        ...state,
        new: {
          ...state.new,
          isChosenCapFromCap: action.payload.isFromCap,
        },
      };

    case ActionTypes.CREATE_REQUEST:
      return {
        ...state,
        new: {
          ...state.new,
          ui: {
            ...state.new.ui,
            createError: undefined,
            isCreateFail: false,
            isCreatePending: true,
            isCreateSuccess: false,
          },
        },
      };
    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        new: {
          ...state.new,
          lastCreatedId: action.payload.recommendationId,
          ui: {
            ...state.new.ui,
            createError: undefined,
            isCreateFail: false,
            isCreatePending: false,
            isCreateSuccess: true,
          },
        },
      };
    case ActionTypes.CREATE_FAIL:
      return {
        ...state,
        new: {
          ...state.new,
          ui: {
            ...state.new.ui,
            createError:
              action.payload && action.payload.message
                ? action.payload.message
                : "Errore sconosciuto, riprova più tardi",
            isCreateFail: true,
            isCreatePending: false,
            isCreateSuccess: false,
          },
        },
      };
    case ActionTypes.CREATE_RESET:
      return {
        ...state,
        new: {
          ...state.new,
          lastCreatedId: undefined,
          ui: {
            ...state.new.ui,
            createError: undefined,
            isCreateFail: false,
            isCreatePending: false,
            isCreateSuccess: false,
          },
        },
      };

    case ActionTypes.READ_REQUEST:
      return {
        ...state,
        view: {
          ...state.view,
          ui: {
            ...state.view.ui,
            isReadFail: false,
            isReadPending: true,
            isReadSuccess: false,
            readError: undefined,
          },
        },
      };
    case ActionTypes.READ_SUCCESS:
      return {
        ...state,
        view: {
          ...state.view,
          ui: {
            ...state.view.ui,
            isReadFail: false,
            isReadPending: false,
            isReadSuccess: true,
            readError: undefined,
          },
        },
      };
    case ActionTypes.READ_FAIL:
      return {
        ...state,
        view: {
          ...state.view,
          ui: {
            ...state.view.ui,
            isReadFail: true,
            isReadPending: false,
            isReadSuccess: false,
            readError:
              action.payload && action.payload.error.message
                ? action.payload.error.message
                : "Errore sconosciuto, riprova più tardi",
          },
        },
      };

    case ActionTypes.LIST_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          ui: {
            ...state.list.ui,
            isListFail: false,
            isListPending: true,
            isListSuccess: false,
            listError: undefined,
          },
        },
      };
    case ActionTypes.LIST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          items: action.payload.recommendationIds,
          numItems: action.payload.numRecommendations,
          params: action.payload.params,
          ui: {
            ...state.list.ui,
            isListFail: false,
            isListPending: false,
            isListSuccess: true,
            listError: undefined,
          },
        },
      };
    case ActionTypes.LIST_FAIL:
      return {
        ...state,
        list: {
          ...state.list,
          ui: {
            ...state.list.ui,
            isListFail: true,
            isListPending: false,
            isListSuccess: false,
            listError:
              action.payload && action.payload.message
                ? action.payload.message
                : "Errore sconosciuto, riprova più tardi",
          },
        },
      };

    case ActionTypes.UPDATE_REQUEST: {
      let updatePerType = {};
      if (action.payload.updateType) {
        updatePerType = {
          isUpdateFailPerType: {
            ...state.view.ui.isUpdateFailPerType,
            [action.payload.updateType]: false,
          },
          isUpdatePendingPerType: {
            ...state.view.ui.isUpdatePendingPerType,
            [action.payload.updateType]: true,
          },
          isUpdateSuccessPerType: {
            ...state.view.ui.isUpdateSuccessPerType,
            [action.payload.updateType]: false,
          },
          updateErrorPerType: {
            ...state.view.ui.updateErrorPerType,
            [action.payload.updateType]: undefined,
          },
        };
      }
      return {
        ...state,
        view: {
          ...state.view,
          ui: {
            ...state.view.ui,
            isUpdateFail: false,
            isUpdatePending: true,
            isUpdateSuccess: false,
            updateError: undefined,
            ...(updatePerType ? updatePerType : {}),
          },
        },
      };
    }
    case ActionTypes.UPDATE_SUCCESS: {
      let updatePerType = {};
      if (action.payload.updateType) {
        updatePerType = {
          isUpdateFailPerType: {
            ...state.view.ui.isUpdateFailPerType,
            [action.payload.updateType]: false,
          },
          isUpdatePendingPerType: {
            ...state.view.ui.isUpdatePendingPerType,
            [action.payload.updateType]: false,
          },
          isUpdateSuccessPerType: {
            ...state.view.ui.isUpdateSuccessPerType,
            [action.payload.updateType]: true,
          },
          updateErrorPerType: {
            ...state.view.ui.updateErrorPerType,
            [action.payload.updateType]: undefined,
          },
        };
      }
      return {
        ...state,
        view: {
          ...state.view,
          ui: {
            ...state.view.ui,
            isUpdateFail: false,
            isUpdatePending: false,
            isUpdateSuccess: true,
            updateError: undefined,
            ...(updatePerType ? updatePerType : {}),
          },
        },
      };
    }
    case ActionTypes.UPDATE_FAIL: {
      let updatePerType = {};
      if (action.payload.updateType) {
        updatePerType = {
          isUpdateFailPerType: {
            ...state.view.ui.isUpdateFailPerType,
            [action.payload.updateType]: true,
          },
          isUpdatePendingPerType: {
            ...state.view.ui.isUpdatePendingPerType,
            [action.payload.updateType]: false,
          },
          isUpdateSuccessPerType: {
            ...state.view.ui.isUpdateSuccessPerType,
            [action.payload.updateType]: false,
          },
          updateErrorPerType: {
            ...state.view.ui.updateErrorPerType,
            [action.payload.updateType]:
              action.payload && action.payload.error.message
                ? action.payload.error.message
                : "Errore sconosciuto, riprova più tardi",
          },
        };
      }
      return {
        ...state,
        view: {
          ...state.view,
          ui: {
            ...state.view.ui,
            isUpdateFail: true,
            isUpdatePending: false,
            isUpdateSuccess: false,
            updateError:
              action.payload && action.payload.error.message
                ? action.payload.error.message
                : "Errore sconosciuto, riprova più tardi",
            ...(updatePerType ? updatePerType : {}),
          },
        },
      };
    }

    case ActionTypes.ASYNC_WARNING_REQUEST:
      return {
        ...state,
        new: {
          ...state.new,
          warn: undefined,
          ui: {
            ...state.new.ui,
            asyncWarningError: undefined,
            isAsyncWarningFail: false,
            isAsyncWarningPending: true,
            isAsyncWarningSuccess: false,
          },
        },
      };
    case ActionTypes.ASYNC_WARNING_SUCCESS:
      return {
        ...state,
        new: {
          ...state.new,
          warn: action.payload.warn,
          ui: {
            ...state.new.ui,
            asyncWarningError: undefined,
            isAsyncWarningFail: false,
            isAsyncWarningPending: false,
            isAsyncWarningSuccess: true,
          },
        },
      };
    case ActionTypes.ASYNC_WARNING_FAIL:
      return {
        ...state,
        new: {
          ...state.new,
          warn: undefined,
          ui: {
            ...state.new.ui,
            asyncWarningError:
              action.payload && action.payload.error.message
                ? action.payload.error.message
                : "Errore sconosciuto, riprova più tardi",
            isAsyncWarningFail: true,
            isAsyncWarningPending: false,
            isAsyncWarningSuccess: false,
          },
        },
      };
    case ActionTypes.ASYNC_WARNING_RESET:
      return {
        ...state,
        new: {
          ...state.new,
          warn: undefined,
          ui: {
            ...state.new.ui,
            asyncWarningError: undefined,
            isAsyncWarningFail: false,
            isAsyncWarningPending: false,
            isAsyncWarningSuccess: false,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
