import {schema} from "normalizr";
import {capSchema, ICapDB, ICapsEntities, IUserSnapshot} from "../Caps/types";
import {entityUtilityFactory} from "../helpers/entityUtilityFactory";
import {parseISO} from "../types/dates";
import {IUserDB, IUsersEntities, userSchema} from "../Users/types";
import {IRCFormData} from "./shared/ChoseProductLevel/Rc";
import {IBeneLevel, IBeneType} from "./shared/NewBeneProposalForm";

export interface IBeneProposalDB {
  cap: ICapDB;
  contractor: IUserDB;
  date: string;
  id: string;
  jsonData?: string;
  jsonProduct: string;
  jsonUser: string;
  status: BeneProposalStatus;
  user: IUserDB;
  jsonDocs?: string;
  declarationConsistencyEsignId?: string;
  deliveryEsignId?: string;
  jsonDatesSendEmail: string;
}

export interface IBeneProposalEntity {
  cap: string;
  contractor: string;
  date: string;
  id: string;
  jsonData?: string;
  jsonProduct: string;
  jsonUser: string;
  status: BeneProposalStatus;
  user: string;
  jsonDocs?: string;
  declarationConsistencyEsignId: string;
  deliveryEsignId?: string;
  jsonDatesSendEmail: string;
}

export interface IBeneProposal {
  cap: string;
  contractor: string;
  date: Date;
  id: string;
  data?: IDataSnapshot;
  product: IProductSnapshot;
  userSnapshot: IUserSnapshot;
  status: BeneProposalStatus;
  user: string;
  docs?: IDocs;
  declarationConsistencyEsignId: string;
  deliveryEsignId?: string;
  datesSendEmail: IDatesSendEmailSnapshot;
}

export enum BeneProposalStatus {
  Disabled = "0",
  Enabled = "1",
  Intermediate = "2",
}

interface IDocs {
  flagReadAllegato3: string;
  flagReadAllegato4Ter: string;
  flagReadAllegato4: string;
  flagReadAvvenutaConsegna: string;
  flagReadSetInformativo: string;
  flagReadDichiarazioneCoerenza: string;
}

export type IDatesSendEmailSnapshot = Array<{date: string}>;

export type IDataSnapshot = Array<{
  outputAccessoProdotto?: {
    uid: string | null;
    outcome?: {
      messages: string[] | null;
    };
  };
  date: string;
}>;

interface IProductSnapshot {
  type: IBeneType;
  level: IBeneLevel;
  rc: IRCFormData;
}

export interface IBeneProposalParams {
  limit: number;
  offset: number;
  contractor?: string;
  date?: string;
  sort?: string;
  user?: string;
}

export type BohFiles = Array<{
  emailSentDate: string;
}>;

const beneProposalSchema = new schema.Entity("beneProposals", {
  cap: capSchema,
  contractor: userSchema,
  user: userSchema,
});

export interface IBeneProposalsEntities {
  [key: string]: IBeneProposalEntity;
}

export type EntityShape = {
  beneProposals: IBeneProposalsEntities;
  caps: ICapsEntities;
  users: IUsersEntities;
};

const beneProposalParse = (beneProposalGeneric: IBeneProposalEntity) => {
  const {
    date,
    jsonData,
    jsonProduct,
    jsonUser,
    jsonDatesSendEmail,
    jsonDocs,
    ...rest
  } = beneProposalGeneric;
  const beneProposal: IBeneProposal = {
    ...rest,
    date: parseISO(date),
    data: jsonData ? (JSON.parse(jsonData) as IDataSnapshot) : undefined,
    product: JSON.parse(jsonProduct) as IProductSnapshot,
    userSnapshot: JSON.parse(jsonUser) as IUserSnapshot,
    datesSendEmail: JSON.parse(jsonDatesSendEmail) as IDatesSendEmailSnapshot,
    docs: jsonDocs ? (JSON.parse(jsonDocs) as IDocs) : undefined,
  };

  return beneProposal;
};

const beneProposalStringify = (beneProposalGeneric: IBeneProposal) => {
  const {date, data, product, userSnapshot, datesSendEmail, docs, ...rest} =
    beneProposalGeneric;
  const beneProposal: IBeneProposalEntity = {
    ...rest,
    date: date.toISOString(),
    jsonData: data && JSON.stringify(data),
    jsonProduct: JSON.stringify(product),
    jsonUser: JSON.stringify(userSnapshot),
    jsonDatesSendEmail: JSON.stringify(datesSendEmail),
    jsonDocs: docs && JSON.stringify(docs),
  };

  return beneProposal;
};

export const beneProposalEntityUtility = entityUtilityFactory<
  IBeneProposalEntity,
  IBeneProposalDB,
  IBeneProposal,
  EntityShape
>({
  schema: beneProposalSchema,
  deserialize: beneProposalParse,
  serialize: beneProposalStringify,
});
