import {usePreviousValue} from "beautiful-react-hooks";
import {FC, useEffect} from "react";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router";
import {actions} from "./slice";

type IRouteState = {
  disableAutoScrollToTop?: boolean;
} | null;

const ScrollToTop: FC = ({children}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const disableAutoScrollToTop = (location.state as IRouteState)
    ?.disableAutoScrollToTop;

  const prevLocation = usePreviousValue(location);
  console.log({prevLocation, location});

  useEffect(() => {
    if (!disableAutoScrollToTop) {
      window.scrollTo(0, 0);
    }

    // dispatch(actions.closeNav());
  }, [location, disableAutoScrollToTop, dispatch]);

  return <>{children}</>;
};

export default ScrollToTop;
