import {ArrayElement} from "../../../../types/ArrayElement";
import {
  bearTheLossesCriteria,
  experienceCriteria,
  financialSituationCriteria,
  investmentObjectiveCriteria,
  riskToleranceCriteria,
  timeFrameCriteria,
} from "./domandeIdd";
import {IIddFormData} from "./IddForm";

export interface IIddProfile {
  customerType: {
    label: "Retail";
  };
  experience: {
    label: ArrayElement<typeof experienceCriteria>;
    value: number; // [0..1]
  };
  financialSituation: {
    label: ArrayElement<typeof financialSituationCriteria>;
    value: number; // [0..1]
  };
  investmentObjective: {
    label: ArrayElement<typeof investmentObjectiveCriteria>;
    value: number; // [0..1]
  };
  timeFrame: {
    label: ArrayElement<typeof timeFrameCriteria>;
    value: number; // [1,3,5,100]
  };
  riskTolerance: {
    label: ArrayElement<typeof riskToleranceCriteria>;
    value: number; // [1..7]
  };
  bearTheLosses: {
    label: ArrayElement<typeof bearTheLossesCriteria>;
    value: number;
  };
}

export const computeIdd = (data: IIddFormData): IIddProfile => {
  const customerType = computeCustomerType(data);
  const experience = computeExperience(data);
  const financialSituation = computeFinancialSituation(data);
  const investmentObjective = computeInvestmentObjective(data);
  const timeFrame = computeTimeFrame(data);
  const riskTolerance = computeRiskTolerance(data);
  const bearTheLosses = computeBearTheLosses(data);

  return {
    customerType: {
      label: customerType,
    },
    experience: {
      label: getExperienceLabel(experience),
      value: experience,
    },
    financialSituation: {
      label: getFinancialSituationLabel(financialSituation),
      value: financialSituation,
    },
    investmentObjective: {
      label: getInvestmentObjectiveLabel(investmentObjective),
      value: investmentObjective,
    },
    timeFrame: {
      label: getTimeFrameLabel(timeFrame),
      value: timeFrame,
    },
    riskTolerance: {
      label: getRiskToleranceLabel(riskTolerance),
      value: riskTolerance,
    },
    bearTheLosses: {
      label: getBearTheLossesLabel(bearTheLosses),
      value: bearTheLosses,
    },
  };
};

const computeCustomerType = (data: IIddFormData) => {
  return "Retail" as const;
};

const computeExperience = (data: IIddFormData) => {
  // B1
  const scoreB1Value = [0, 0, 1, 2, 3];
  const scoreB1 = scoreB1Value[parseInt(data.question_b1, 10) - 1];

  // C1
  const scoreC1Value = [1, 0, 0.5];
  const scoreC1 =
    scoreC1Value[parseInt(data.question_c1a, 10) - 1] +
    scoreC1Value[parseInt(data.question_c1b, 10) - 1] +
    scoreC1Value[parseInt(data.question_c1c, 10) - 1];

  // C2
  const scoreC2Value = [1, 0, 0.5];
  const scoreC2 = scoreC2Value[parseInt(data.question_c2, 10) - 1];

  // C3
  const scoreC3Value = [1, 0, 0.5];
  const scoreC3 = scoreC3Value[parseInt(data.question_c3, 10) - 1];

  // C4
  const scoreC4Value = [1, 0];
  const scoreC4 = scoreC4Value[parseInt(data.question_c4, 10) - 1];

  // C5
  const scoreC5Value = [0, 1, 2, 3];
  const scoreC5 = scoreC5Value[parseInt(data.question_c5, 10) - 1];

  // C6
  const scoreC6Value = [0, 1, 2, 3, 4, 5];
  const scoreC6 = scoreC6Value[parseInt(data.question_c6, 10) - 1];

  // Totale normalizzato e pesato
  return (
    (scoreB1 / Math.max(...scoreB1Value)) * 0.08 +
    (scoreC1 / Math.max(...scoreC1Value)) * 0.13 +
    (scoreC2 / Math.max(...scoreC2Value)) * 0.13 +
    (scoreC3 / Math.max(...scoreC3Value)) * 0.13 +
    (scoreC4 / Math.max(...scoreC4Value)) * 0.09 +
    (scoreC5 / Math.max(...scoreC5Value)) * 0.09 +
    (scoreC6 / Math.max(...scoreC6Value)) * 0.09
  );
};

const computeFinancialSituation = (data: IIddFormData) => {
  // D1
  const scoreD1Value = [0, 1, 2, 3, 4];
  const scoreD1 = scoreD1Value[parseInt(data.question_d1, 10) - 1];

  // D2
  const scoreD2Value = [1, 2, 3, 0];
  const scoreD2 = scoreD2Value[parseInt(data.question_d2, 10) - 1];

  // D4
  const scoreD4Value = [0, 1, 2, 3, 4];
  const scoreD4 = scoreD4Value[parseInt(data.question_d4, 10) - 1];

  // D5
  const scoreD5Value = [3, 2, 1, 0];
  const scoreD5 = scoreD5Value[parseInt(data.question_d5, 10) - 1];

  // D6
  const scoreD6Value = [4, 3, 2, 1, 0];
  const scoreD6 = scoreD6Value[parseInt(data.question_d6, 10) - 1];

  // Totale normalizzato e pesato
  return (
    (scoreD1 / Math.max(...scoreD1Value)) * 0.2 +
    (scoreD2 / Math.max(...scoreD2Value)) * 0.2 +
    (scoreD4 / Math.max(...scoreD4Value)) * 0.2 +
    (scoreD5 / Math.max(...scoreD5Value)) * 0.2 +
    (scoreD6 / Math.max(...scoreD6Value)) * 0.2
  );
};

const computeInvestmentObjective = (data: IIddFormData) => {
  // E1
  const scoreE1Value = [0, 1, 2];
  const scoreE1 = scoreE1Value[parseInt(data.question_e1, 10) - 1];

  // Totale normalizzato e pesato
  return scoreE1;
};

const computeTimeFrame = (data: IIddFormData) => {
  console.log({data});
  // F1
  const scoreF1Value = [1, 3, 5, 100];
  const scoreF1 = scoreF1Value[parseInt(data.question_f1, 10) - 1];
  console.log(parseInt(data.question_f1, 10));
  console.log(parseInt(data.question_f1, 10) - 1);
  console.log(scoreF1Value[parseInt(data.question_f1, 10) - 1]);
  console.log({scoreF1});
  // Totale normalizzato e pesato
  return scoreF1;
};

const computeRiskTolerance = (data: IIddFormData) => {
  // G1
  const scoreG1Value = [0, 2, 2.5, 3];
  const scoreG1 = scoreG1Value[parseInt(data.question_g1, 10) - 1];

  // G2
  const scoreG2Value = [0, 2, 2.5, 3];
  const scoreG2 = scoreG2Value[parseInt(data.question_g2, 10) - 1];

  // Totale normalizzato e pesato
  const normalizedScore =
    (scoreG1 / Math.max(...scoreG1Value)) * 0.5 +
    (scoreG2 / Math.max(...scoreG2Value)) * 0.5;

  // Ritorna 1..7
  return Math.floor(normalizedScore * 6 + 1);
};

const computeBearTheLosses = (data: IIddFormData) => {
  // G2
  const scoreG2Value = [0, 1, 2, 3];
  const scoreG2 = scoreG2Value[parseInt(data.question_g2, 10) - 1];

  // Ritorna 0..3
  return scoreG2;
};

export const getExperienceLabel = (value: number) => {
  // Soglie da excel
  const thresholds = [0.4, 0.75, 1];
  const index = thresholds.findIndex((threshold) => value <= threshold);

  return experienceCriteria[index];
};

export const getFinancialSituationLabel = (value: number) => {
  // Soglie da excel
  const thresholds = [0.15, 0.4, 0.7, 1];
  const index = thresholds.findIndex((threshold) => value <= threshold);

  return financialSituationCriteria[index];
};

export const getInvestmentObjectiveLabel = (value: number) => {
  return investmentObjectiveCriteria[value];
};

export const getTimeFrameLabel = (value: number) => {
  // Soglie da excel
  const thresholds = [1, 3, 5, 100];
  const index = thresholds.findIndex((threshold) => value <= threshold);

  return timeFrameCriteria[index];
};

export const getRiskToleranceLabel = (value: number) => {
  return riskToleranceCriteria[value - 1];
};

export const getBearTheLossesLabel = (value: number) => {
  // Soglie da excel
  const thresholds = [1, 3, 100];
  const index = thresholds.findIndex((threshold) => value < threshold);

  return bearTheLossesCriteria[index];
};
