import {ICap} from "../../../types";
import {SingleKeys} from "./IddForm";

export const experienceCriteria = ["Basic", "Informed", "Advanced"] as const;
export const financialSituationCriteria = [
  "Critica",
  "Debole",
  "Moderata",
  "Solida",
] as const;
export const investmentObjectiveCriteria = [
  "Conservazione",
  "Rendita",
  "Crescita",
] as const;
export const timeFrameCriteria = [
  "Brevissimo periodo (<12 mesi)",
  "Breve periodo (1-3 anni)",
  "Medio periodo (3-5 anni)",
  "Lungo periodo (tra 5 e 10 anni ed oltre)",
] as const;
export const riskToleranceCriteria = [
  "Molto basso",
  "Basso",
  "Medio basso",
  "Medio",
  "Medio alto",
  "Alto",
  "Molto Alto",
] as const;
export const bearTheLossesCriteria = ["Bassa", "Media", "Alta"];

interface IDomandeIddQuestion {
  answers: Array<{text: string; value: string}>;
  label: string;
  multiple?: boolean;
  name: SingleKeys;
  getInitialValue?: (cap: ICap) => string | Array<null | true>;
}

interface IDomandeIddSection {
  questions: IDomandeIddQuestion[];
  title: string;
}

export interface IDomandeIdd {
  sections: IDomandeIddSection[];
}

const domandeIdd: IDomandeIdd = {
  sections: [
    {
      questions: [
        {
          answers: [
            {text: "Nessun titolo di studio", value: "1"},
            {text: "Licenza scuola primaria/media", value: "2"},
            {text: "Diploma di scuola superiore", value: "3"},
            {text: "Laurea Magistrale / Laurea Triennale", value: "4"},
            {
              text: "Laurea o specializzazione post-universitaria in campo economico finanziario",
              value: "5",
            },
          ],
          label: "Qual è il suo livello di istruzione?",
          name: "question_b1",
        },
        {
          answers: [],
          label:
            "A suo parere, un'obbligazione emessa da uno stato, pur rimborsando il valore nominale a scadenza:",
          name: "question_c1",
        },
        {
          answers: [
            {text: "Sì", value: "1"},
            {text: "No", value: "2"},
            {text: "Non so", value: "3"},
          ],
          label:
            "Può presentare nel corso della durata dell'investimento un valore del capitale inferiore rispetto a quello investito?",
          name: "question_c1a",
        },
        {
          answers: [
            {text: "Sì", value: "1"},
            {text: "No", value: "2"},
            {text: "Non so", value: "3"},
          ],
          label:
            "Presenta un rischio che dipende dalla variabilità dei tassi di interesse e dall'eventuale rischio di cambio (valuta)?",
          name: "question_c1b",
        },
        {
          answers: [
            {text: "Sì", value: "1"},
            {text: "No", value: "2"},
            {text: "Non so", value: "3"},
          ],
          label:
            "Nel caso di un'obbligazione Corporate il rendimento può essere pù elevato di un Titolo di Stato a fronte di un rischio di credito più elevato?",
          name: "question_c1c",
        },
        {
          answers: [
            {text: "Sì", value: "1"},
            {text: "No", value: "2"},
            {text: "Non so", value: "3"},
          ],
          label:
            "Le obbligazioni strutturate hanno lo stesso rischio delle obbligazioni ordinarie?",
          name: "question_c2",
        },
        {
          answers: [
            {text: "Sì", value: "1"},
            {text: "No", value: "2"},
            {text: "Non so", value: "3"},
          ],
          label:
            "È a conoscenza che come conseguenza di un investimento in titoli azionari è possibile incorrere in perdite di parti anche rilevanti del capitale investito?",
          name: "question_c3",
        },
        {
          answers: [
            {text: "Sì", value: "1"},
            {text: "No", value: "2"},
          ],
          label:
            "Il suo attuale o il precedente impegno lavorativo le ha consentito di acquisire esperienze o conoscenze rilevanti in materie economico finanziarie?",
          name: "question_c4",
        },
        {
          answers: [
            {text: "Mai/raramente", value: "1"},
            {text: "Almeno una volta l'anno", value: "2"},
            {text: "Almeno ogni tre mesi", value: "3"},
            {text: "Almeno ogni mese", value: "4"},
          ],
          label:
            "Con che frequenza realizza mediamente i suoi investimenti in strumenti finanziari?",
          name: "question_c5",
        },
        {
          answers: [
            {text: "Non ho mai effettuato investimenti", value: "1"},
            {
              text: "Ho effettuato occasionalmente investimenti in strumenti finanziari semplici o a basso rischio (es. cd, pct, obbligazioni, prodotti a capitale garantito, ecc.)",
              value: "2",
            },
            {
              text: "Ho effettuato investimenti nei principali strumenti finanziari (ad esempio azioni, fondi)",
              value: "3",
            },
            {
              text: "Ho effettuato investimenti in altri strumenti finanziari ed assicurativi (ad esempio gestioni bilanciate ed azionarie, polizze unit linked, polizze multiramo)",
              value: "4",
            },
            {
              text: "Ho effettuato investimenti anche in strumenti finanziari complessi e/o a rischio elevato comprendendone i principali rischi in relazione alle dinamiche di mercato (ad esempio fondi azionari paesi emergenti, hedge fund, derivati)",
              value: "5",
            },
            {text: "Altri prodotti complessi", value: "6"},
          ],
          label:
            "Quale delle seguenti affermazioni descrive meglio la sua operatività negli ultimi 3 anni e la categoria di strumenti finanziari nella quali ha eseguito operazioni?",
          name: "question_c6",
        },
      ],
      title: "Esperienza",
    },
    {
      questions: [
        {
          answers: [
            {text: "Inferiore o uguale a 15.000 euro", value: "1"},
            {
              text: "Superiore a 15.000 euro e inferiore o uguale a 30.000 euro",
              value: "2",
            },
            {
              text: "Superiore a 30.000 euro e inferiore o uguale a 45.000 euro",
              value: "3",
            },
            {
              text: "Superiore a 45.000 euro e inferiore o uguale a 70.000 euro",
              value: "4",
            },
            {text: "Superiore a 70.000 euro", value: "5"},
          ],
          getInitialValue: (cap) => {
            const netYearIncome =
              cap.data.employments!.currentEmployment.redditoNormalizzato * 12 +
              cap.data.incomes!.incomes.reduce((total, income) => {
                return total + (parseFloat(income.value) * 12 || 0);
              }, 0);
            if (netYearIncome < 15000) {
              return "1";
            } else if (netYearIncome < 30000) {
              return "2";
            } else if (netYearIncome < 45000) {
              return "3";
            } else if (netYearIncome < 70000) {
              return "4";
            } else {
              return "5";
            }
          },
          label: "Qual è la sua capacità reddituale annua netta?",
          name: "question_d1",
        },
        {
          answers: [
            {text: "Fino a euro 5.000", value: "1"},
            {text: "Da euro 5.000 a euro 15.000", value: "2"},
            {text: "Oltre Euro 15.000", value: "3"},
            {text: "Nessuno", value: "4"},
          ],
          label:
            "Qual è la sua capacità di risparmio medio annuo, tenendo conto delle sue fonti di reddito (al netto di eventuali impegni finanziari ed altre spese)?",
          name: "question_d2",
        },
        {
          answers: [
            {text: "Lavoro", value: "1"},
            {
              text: "Investimenti",
              value: "2",
            },
            {
              text: "Rendita da proprietà, terreni, immobili",
              value: "3",
            },
            {
              text: "Altro",
              value: "4",
            },
            {text: "Non risponde", value: "5"},
          ],
          label: "Qual è la fonte principale del suo reddito?",
          name: "question_d3",
        },
        {
          answers: [
            {text: "Contenuto (inferiore a euro 20.000)", value: "1"},
            {
              text: "Consistente (oltre euro 20.000 fino a euro 100.000)",
              value: "2",
            },
            {
              text: "Significativo (oltre euro 100.000 fino a euro 250.000)",
              value: "3",
            },
            {text: "Rilevante (oltre euro 250.000 fino a 500.000)", value: "4"},
            {text: "Molto rilevante (oltre euro 500.000)", value: "5"},
          ],
          label:
            "Qual è la consistenza del suo patrimonio complessivo in termini di prodotti finanziari, immobili e liquidità?",
          name: "question_d4",
        },
        {
          answers: [
            {text: "Inferiori o uguali a 15.000 euro", value: "1"},
            {
              text: "Superiori a 15.000 euro e inferiori o uguali a 30.000 euro",
              value: "2",
            },
            {
              text: "Superiori a 30.000 euro e inferiori o uguali a 60.000 euro",
              value: "3",
            },
            {
              text: "Superiori a 60.000 euro",
              value: "4",
            },
          ],
          label:
            "A quanto ammontano i suoi impegni finanziari ancora da rimborsare?",
          name: "question_d5",
        },
        {
          answers: [
            {text: "Occupato a tempo indeterminato", value: "1"},
            {text: "Autonomo o libero professionista", value: "2"},
            {text: "Pensionato", value: "3"},
            {
              text: "Altro (esempio contratto a tempo determinato, interinale, stagionale, ecc)",
              value: "4",
            },
            {text: "Non occupato", value: "5"},
          ],
          label: "Quale è attualmente il suo stato occupazionale?",
          name: "question_d6",
        },
      ],
      title: "Situazione finanziaria",
    },
    {
      questions: [
        {
          answers: [
            {
              text: "Preservare il premio versato anche a scapito del rendimento",
              value: "1",
            },
            {
              text: "Ricevere un flusso cedolare periodico anche minimo",
              value: "2",
            },
            {
              text: "Ottenere una possibile crescita del patrimonio sopportando un maggior rischio sul premio investito",
              value: "3",
            },
          ],
          label: "Quali sono gli obiettivi del suo investimento?",
          name: "question_e1",
        },
        {
          answers: [
            {text: "Sì", value: "1"},
            {text: "No", value: "2"},
            {text: "Indifferente", value: "3"},
          ],
          label: "Preferirebbe investire in fondi ESG?",
          name: "question_e2",
        },
      ],
      title: "Obiettivi di investimento",
    },
    {
      questions: [
        {
          answers: [
            {
              text: "Brevissimo periodo (<12 mesi)",
              value: "1",
            },
            {
              text: "Breve periodo (1-3 anni)",
              value: "2",
            },
            {
              text: "Medio periodo (3-5 anni)",
              value: "3",
            },
            {
              text: "Lungo periodo (tra 5 e 10 anni ed oltre)",
              value: "4",
            },
          ],
          label:
            "Qual è il periodo di tempo per il quale desidera conservare l'investimento?",
          name: "question_f1",
        },
      ],
      title: "Orizzonte temporale",
    },
    {
      questions: [
        {
          answers: [
            {
              text: "Smobilizzerei gli investimenti, trasferendo il capitale su strumenti finanziari meno volatili",
              value: "1",
            },
            {
              text: "Manterrei gli investimenti, monitorando frequentemente l’andamento del mercato",
              value: "2",
            },
            {
              text: "Manterrei gli investimenti, considerando le oscillazioni dei prezzi nel breve termine come fisiologiche",
              value: "3",
            },
            {
              text: "Aumenterei gli investimenti per sfruttare l’opportunità di mercato",
              value: "4",
            },
          ],
          label:
            "In un periodo di forte volatilità dei mercati, il Vostro portafoglio potrebbe subire variazioni significative. Come reagireste? (Indicare la risposta che meglio descrive il vostro effettivo comportamento)",
          name: "question_g1",
        },
        {
          answers: [
            {
              text: "Fino al 5%",
              value: "1",
            },
            {
              text: "Fino al 10%",
              value: "2",
            },
            {
              text: "Fino al 15%",
              value: "3",
            },
            {
              text: "Superiore al 15%",
              value: "4",
            },
          ],
          label:
            "Ipotizzando un andamento negativo dei mercati finanziari, qual è la perdita massima che sarebbe disposto a sopportare senza sentire la necessità di liquidare tutto o in parte i suoi investimenti?",
          name: "question_g2",
        },
      ],
      title: "Propensione al rischio",
    },
  ],
};

export default domandeIdd;
