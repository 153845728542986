import moment from "moment";
import {denormalize, normalize, schema} from "normalizr";
import {IdTypes} from "../Caps/NewPage/ClosingSection/IdentificationDrawer/UploadIdForm";

export enum userRoles {
  "Advisor" = 2,
  "Supervisore" = 3,
  "Backoffice" = 5,
  "Gestione Utenti" = 6,
  "Contractor" = 7,
}

export type MaritalStatuses =
  | "celibe"
  | "coniugato"
  | "convivente"
  | "divorziato"
  | "vedovo";

export type IEducationTypes = "1" | "2" | "3" | "4" | "5";

export type IGenderTypes = "m" | "f";

export interface IIdentificationSnapshot {
  advisorAccept: boolean;
  esignId?: string;
  contractorEsignId?: string;
  date: string;
  expirationDate: string;
  fileIdBackName: string;
  fileIdFrontName: string;
  fileIdFaceName?: string;
  fileIdFCName?: string;
  idNumber: string;
  idType: keyof typeof IdTypes;
  releaseDate: string;
  releasedBy: string;
}

export type IIdentificationHistory = IIdentificationSnapshot[];

export interface IPrivacySnapshot {
  version: string;
  values: {[Key: string]: boolean};
  date: string;
  esignId?: string;
}

// Privacy nuove sono array, ma le vecchie possono essere o oggetti snapshot oppure
// true per gli advisor
export type IPrivacyHistory = IPrivacySnapshot[] | IPrivacySnapshot | "true";

export type IPrivacySubscriptionHistory = IPrivacySnapshot[];

// È l'interfaccia degli user salvati nello store redux
//  i.e. senza tipi e con gli id delle altre entità
export interface IUserEntity {
  address?: string;
  streetNumber?: string;
  birthday?: string;
  birthplace?: string;
  birthplaceBExist: string;
  birthplaceCSiglaProvincia: string;
  birthplaceIdObject: string;
  businessPhone?: string;
  city?: string;
  companiesId?: {[key: number]: boolean};
  companyRole?: string;
  companyUserId?: string;
  dateCreated?: string;
  dateRui?: string;
  definitiveCollaborationCode?: string;
  education?: IEducationTypes;
  email: string;
  fiscalCode?: string;
  gender?: IGenderTypes;
  id: string;
  jsonIdentificationHistory?: string;
  jsonPrivacy?: string;
  jsonPrivacySubscription?: string;
  lastLogin?: string;
  lastPrivacyEsignId?: string;
  lastPrivacySubscriptionEsignId?: string;
  maritalStatus?: MaritalStatuses;
  name: string;
  password: string;
  personalEmail?: string;
  personalPhone?: string;
  profitCenter?: string;
  referenceSuperiorUserId?: string;
  region?: string;
  rolesId?: {[key: number]: boolean};
  ruiCode?: string;
  status: string;
  surname: string;
  temporaryCollaborationCode?: string;
  usid: string;
  vatNumber?: string;
  zipCode?: string;
  companyName?: string;
  position?: string;
  ruiCodeCompany?: string;
  dateRuiCompany?: string;
  registeredOffice?: string;
  website?: string;
  pec?: string;
}

// È l'interfaccia degli user come arrivano dal db
//  i.e. senza tipi ma con le altre entità esplicitate
export interface IUserDB {
  address?: string;
  streetNumber?: string;
  birthday?: string;
  birthplace?: string;
  birthplaceBExist: string;
  birthplaceCSiglaProvincia: string;
  birthplaceIdObject: string;
  businessPhone?: string;
  city?: string;
  companiesId?: {[key: number]: boolean};
  companyRole?: string;
  companyUserId?: string;
  dateCreated?: string;
  dateRui?: string;
  definitiveCollaborationCode?: string;
  education?: IEducationTypes;
  email: string;
  fiscalCode?: string;
  gender?: IGenderTypes;
  id: string;
  jsonIdentificationHistory?: string;
  jsonPrivacy?: string;
  jsonPrivacySubscription?: string;
  lastLogin?: string;
  lastPrivacyEsignId?: string;
  lastPrivacySubscriptionEsignId?: string;
  maritalStatus?: MaritalStatuses;
  name: string;
  password: string;
  personalEmail?: string;
  personalPhone?: string;
  profitCenter?: string;
  referenceSuperiorUserId?: string;
  region?: string;
  rolesId?: {[key: number]: boolean};
  ruiCode?: string;
  status: string;
  surname: string;
  temporaryCollaborationCode?: string;
  usid: string;
  vatNumber?: string;
  zipCode?: string;
  companyName?: string;
  position?: string;
  ruiCodeCompany?: string;
  dateRuiCompany?: string;
  registeredOffice?: string;
  website?: string;
  pec?: string;
}
// È l'interfaccia degli user idratati
//  i.e. con i tipi e con le altre entità esplicitate
export interface IUser {
  address?: string;
  streetNumber?: string;
  birthday?: moment.Moment;
  birthplace?: string;
  birthplaceBExist: string;
  birthplaceCSiglaProvincia: string;
  birthplaceIdObject: string;
  businessPhone?: string;
  city?: string;
  companiesId?: number[];
  companyRole?: string;
  companyUserId?: string;
  dateCreated?: moment.Moment;
  dateRui?: moment.Moment;
  definitiveCollaborationCode?: string;
  education?: IEducationTypes;
  email: string;
  fiscalCode?: string;
  gender?: IGenderTypes;
  id: string;
  identification?: IIdentificationHistory;
  lastLogin?: moment.Moment;
  lastPrivacyEsignId?: string;
  lastPrivacySubscriptionEsignId?: string;
  maritalStatus?: MaritalStatuses;
  name: string;
  password: boolean;
  personalEmail?: string;
  personalPhone?: string;
  privacy?: IPrivacyHistory;
  privacySubscription?: IPrivacySubscriptionHistory;
  profitCenter?: string;
  referenceSuperiorUserId?: string;
  region?: string;
  rolesId?: number[];
  ruiCode?: string;
  status: string;
  surname: string;
  temporaryCollaborationCode?: string;
  usid: string;
  vatNumber?: string;
  zipCode?: string;
  companyName?: string;
  position?: string;
  ruiCodeCompany?: string;
  dateRuiCompany?: string;
  registeredOffice?: string;
  website?: string;
  pec?: string;
}

export interface IDehydratedUsers {
  result: string[];
  entities: {
    users: IUsersEntities;
  };
}
export interface IDehydratedUser {
  result: string;
  entities: {
    users: IUsersEntities;
  };
}

const userEntity = new schema.Entity("users");
export const userSchema = userEntity;

export interface IUsersEntities {
  [key: string]: IUserEntity;
}

const rolesIdObjToArr = (rolesIdObj: {[key: number]: boolean}): number[] => {
  const rolesIdArr: number[] = [];
  Object.keys(rolesIdObj).forEach((key) => {
    rolesIdArr.push(parseInt(key, 10));
  });
  return rolesIdArr;
};
const rolesIdArrToObj = (rolesIdArr: number[]): {[key: number]: boolean} => {
  const rolesIdObj: {[key: number]: boolean} = {};
  rolesIdArr.forEach((role) => {
    rolesIdObj[role] = true;
  });
  return rolesIdObj;
};

export const dehydrateUserProperties = (userGeneric: IUser) => {
  const {
    birthday,
    companiesId,
    dateCreated,
    dateRui,
    identification,
    lastLogin,
    password,
    privacy,
    privacySubscription,
    rolesId,
    ...rest
  } = userGeneric;
  const user: IUserDB = {
    ...rest,
    birthday: birthday && birthday.format("LL"),
    dateCreated: dateCreated && dateCreated.format("LL LTS"),
    dateRui: dateRui && dateRui.format("LL"),
    jsonIdentificationHistory: JSON.stringify(identification),
    jsonPrivacy: JSON.stringify(privacy),
    jsonPrivacySubscription: JSON.stringify(privacySubscription),
    lastLogin: lastLogin && lastLogin.format("LL LTS"),
    password: password.toString(),
    rolesId: rolesId && rolesIdArrToObj(rolesId),
    companiesId: companiesId && rolesIdArrToObj(companiesId),
  };

  return user;
};

export function normalizeUser(user: IUserDB): IDehydratedUser;
export function normalizeUser(user: IUserDB[]): IDehydratedUsers;
export function normalizeUser(user: IUserDB | IUserDB[]) {
  if (Array.isArray(user)) {
    return normalize(user, [userSchema]);
  } else {
    return normalize(user, userSchema);
  }
}

export function dehydrateUser(user: IUser): IDehydratedUser;
export function dehydrateUser(user: IUser[]): IDehydratedUsers;
export function dehydrateUser(user: IUser | IUser[]) {
  if (Array.isArray(user)) {
    // Risultato multiplo
    return normalizeUser(user.map(dehydrateUserProperties));
  } else {
    // Risultato singolo
    return normalizeUser(dehydrateUserProperties(user));
  }
}

export const hydrateUserProperties = (userGeneric: IUserDB) => {
  if (typeof userGeneric === "string") {
    return {id: userGeneric as string} as IUser;
  }
  const {
    birthday,
    companiesId,
    dateCreated,
    dateRui,
    jsonIdentificationHistory,
    jsonPrivacy,
    jsonPrivacySubscription,
    lastLogin,
    password,
    rolesId,
    ...rest
  } = userGeneric;
  const user: IUser = {
    ...rest,
    birthday: birthday ? moment(birthday) : undefined,
    dateCreated: dateCreated ? moment(dateCreated) : undefined,
    dateRui: dateRui ? moment(dateRui) : undefined,
    identification: jsonIdentificationHistory
      ? (JSON.parse(jsonIdentificationHistory) as IIdentificationHistory)
      : undefined,
    lastLogin: lastLogin ? moment(lastLogin) : undefined,
    password: !!password,
    privacy: jsonPrivacy
      ? (JSON.parse(jsonPrivacy) as IPrivacyHistory)
      : undefined,
    privacySubscription: jsonPrivacySubscription
      ? (JSON.parse(jsonPrivacySubscription) as IPrivacySubscriptionHistory)
      : undefined,
    rolesId: rolesId && rolesIdObjToArr(rolesId),
    companiesId: companiesId && rolesIdObjToArr(companiesId),
  };

  return user;
};

export function hydrateUser({result, entities}: IDehydratedUser): IUser;
export function hydrateUser({result, entities}: IDehydratedUsers): IUser[];
export function hydrateUser({
  result,
  entities,
}: IDehydratedUser | IDehydratedUsers): IUser | IUser[] {
  if (typeof result === "string") {
    // Risultato singolo
    return hydrateUserProperties(denormalize(result, userEntity, entities));
  } else {
    // Risultato multiplo
    return denormalize(result, [userEntity], entities).map(
      hydrateUserProperties
    );
  }
}
