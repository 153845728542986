import moment from "moment";
import {batch} from "react-redux";
import {Dispatch} from "redux";
import IApiError from "../types/IApiError";
import {ThunkActionsNames} from "./reducers";
import service from "./service";
import {IUser, IUserEntity, IUsersEntities} from "./types";
import {normalizeUser} from "./types";

export enum actionTypes {
  MERGE_ITEMS = "[Users] Merge items",

  SET_IS_PENDING = "[Users] set the pending state of a thunk",
  SET_IS_SUCCESS = "[Users] set the success state of a thunk",
  SET_IS_FAIL = "[Users] set the fail state of a thunk",
  RESET_STATE = "[Users] reset the state of a thunk",

  OPEN_ENABLE_USER = "[Users] Open enable user modal",
  CLOSE_ENABLE_USER = "[Users] Close enable user modal",

  LIST_REQUEST = "[Users] List request",
  LIST_SUCCESS = "[Users] List success",
  LIST_FAIL = "[Users] List fail",

  CREATE_REQUEST = "[Users] Create request",
  CREATE_SUCCESS = "[Users] Create success",
  CREATE_FAIL = "[Users] Create fail",

  UPDATE_REQUEST = "[Users] Update request",
  UPDATE_SUCCESS = "[Users] Update success",
  UPDATE_FAIL = "[Users] Update fail",

  UPDATE_STATUS_REQUEST = "[Users] Update status request",
  UPDATE_STATUS_SUCCESS = "[Users] Update status success",
  UPDATE_STATUS_FAIL = "[Users] Update status fail",

  RESET_PASSWORD_CONTRACTOR_REQUEST = "[Users] Update password request",
  RESET_PASSWORD_CONTRACTOR_SUCCESS = "[Users] Update password success",
  RESET_PASSWORD_CONTRACTOR_FAIL = "[Users] Update password fail",

  DELETE_REQUEST = "[Users] Delete request",
  DELETE_SUCCESS = "[Users] Delete success",
  DELETE_FAIL = "[Users] Delete fail",
  RESET_DELETE_SUCCESS = "[Users] Reset delete success",

  ENABLE_REQUEST = "[Users] Enable request",
  ENABLE_SUCCESS = "[Users] Enable success",
  ENABLE_FAIL = "[Users] Enable fail",
  RESET_ENABLE_SUCCESS = "[Users] Reset enable success",

  DISABLE_REQUEST = "[Users] Disable request",
  DISABLE_SUCCESS = "[Users] Disable success",
  DISABLE_FAIL = "[Users] Disable fail",
  RESET_DISABLE_SUCCESS = "[Users] Reset disable success",

  FROM_ROUTE = "[Users] New user from route",
  FROM_DB_REQUEST = "[Users] New user from db request",
  FROM_DB_SUCCESS = "[Users] New user from db success",
  FROM_DB_FAIL = "[Users] New user from db fail",
  FROM_DB_RESET_UI_ERROR = "[Users] Reset from db error",
  RESET = "[Users] Reset newUser data",

  VIEW_FROM_ROUTE = "[Users] View user from route",
  VIEW_FROM_DB_REQUEST = "[Users] View user from db request",
  VIEW_FROM_DB_SUCCESS = "[Users] View user from db success",
  VIEW_FROM_DB_FAIL = "[Users] View user from db fail",
  VIEW_FROM_DB_RESET_UI_ERROR = "[Users] Reset from db error",
  VIEW_RESET = "[Users] Reset view cap data",

  UPLOAD_BULK_FILE_REQUEST = "[Users] upload bulk file request",
  UPLOAD_BULK_FILE_SUCCESS = "[Users] upload bulk file success",
  UPLOAD_BULK_FILE_FAIL = "[Users] upload bulk file fail",

  RESET_FILE_STATE = "[Users] reset upload file",
  RESET_NEW_USER_STATE = "[Users] reset new user",

  ACCEPT_BULK_FILE_REQUEST = "[Users] accept bulk file request",
  ACCEPT_BULK_FILE_SUCCESS = "[Users] accept bulk file success",
  ACCEPT_BULK_FILE_FAIL = "[Users] accept bulk file fail",
}

interface IMergeItemsAction {
  type: typeof actionTypes.MERGE_ITEMS;
  payload: IUsersEntities;
}

interface ISetIsPending {
  type: typeof actionTypes.SET_IS_PENDING;
  payload: {thunkActionsName: ThunkActionsNames; id?: string};
}
interface ISetIsSuccess {
  type: typeof actionTypes.SET_IS_SUCCESS;
  payload: {thunkActionsName: ThunkActionsNames; id?: string};
}
interface ISetIsFail {
  type: typeof actionTypes.SET_IS_FAIL;
  error: IApiError;
  payload: {thunkActionsName: ThunkActionsNames; id?: string};
}
interface IResetState {
  type: typeof actionTypes.RESET_STATE;
  payload: {thunkActionsName: ThunkActionsNames; id?: string};
}

interface IListRequestAction {
  type: typeof actionTypes.LIST_REQUEST;
}
interface IListSuccessAction {
  type: typeof actionTypes.LIST_SUCCESS;
  payload: string[];
}
interface IListFailAction {
  type: typeof actionTypes.LIST_FAIL;
  payload: IApiError;
}

interface ICreateRequestAction {
  type: typeof actionTypes.CREATE_REQUEST;
}
interface ICreateSuccessAction {
  type: typeof actionTypes.CREATE_SUCCESS;
  payload: {userId: string};
}
interface ICreateFailAction {
  type: typeof actionTypes.CREATE_FAIL;
  payload: {error: IApiError; userId: number};
}

interface IFromRouteAction {
  type: typeof actionTypes.FROM_ROUTE;
  payload: IUser;
}
interface IFromDbRequestAction {
  type: typeof actionTypes.FROM_DB_REQUEST;
}
interface IFromDbSuccessAction {
  type: typeof actionTypes.FROM_DB_SUCCESS;
  payload: {userId: string};
}
interface IFromDbFailAction {
  type: typeof actionTypes.FROM_DB_FAIL;
  payload: IApiError;
}
interface IFromDbResetUiErrorAction {
  type: typeof actionTypes.FROM_DB_RESET_UI_ERROR;
}
interface IResetAction {
  type: typeof actionTypes.RESET;
}

interface IUpdateRequestAction {
  type: typeof actionTypes.UPDATE_REQUEST;
}
interface IUpdateSuccessAction {
  type: typeof actionTypes.UPDATE_SUCCESS;
  payload: {userId: string};
}
interface IUpdateFailAction {
  type: typeof actionTypes.UPDATE_FAIL;
  payload: IApiError;
}

interface IResetPasswordContractorRequestAction {
  type: typeof actionTypes.RESET_PASSWORD_CONTRACTOR_REQUEST;
}
interface IResetPasswordContractorSuccessAction {
  type: typeof actionTypes.RESET_PASSWORD_CONTRACTOR_SUCCESS;
}
interface IResetPasswordContractorFailAction {
  type: typeof actionTypes.RESET_PASSWORD_CONTRACTOR_FAIL;
  payload: IApiError;
}

interface IUpdateStatusRequestAction {
  type: typeof actionTypes.UPDATE_STATUS_REQUEST;
}
interface IUpdateStatusSuccessAction {
  type: typeof actionTypes.UPDATE_STATUS_SUCCESS;
  payload: IUser;
}
interface IUpdateStatusFailAction {
  type: typeof actionTypes.UPDATE_STATUS_FAIL;
  payload: IApiError;
}

interface IDeleteRequestAction {
  type: typeof actionTypes.DELETE_REQUEST;
  payload: {userId: string};
}
interface IDeleteSuccessAction {
  type: typeof actionTypes.DELETE_SUCCESS;
  payload: {userId: string};
}
interface IDeleteFailAction {
  type: typeof actionTypes.DELETE_FAIL;
  payload: {
    userId: string;
    error: IApiError;
  };
}
interface IResetDeleteSuccess {
  type: typeof actionTypes.RESET_DELETE_SUCCESS;
}

interface IEnableRequestAction {
  type: typeof actionTypes.ENABLE_REQUEST;
  payload: IUser;
}
interface IEnableSuccessAction {
  type: typeof actionTypes.ENABLE_SUCCESS;
  payload: IUser;
}
interface IEnableFailAction {
  type: typeof actionTypes.ENABLE_FAIL;
  payload: {
    user: IUser;
    error: IApiError;
  };
}
interface IResetEnableSuccess {
  type: typeof actionTypes.RESET_ENABLE_SUCCESS;
}

interface IDisableRequestAction {
  type: typeof actionTypes.DISABLE_REQUEST;
  payload: IUser;
}
interface IDisableSuccessAction {
  type: typeof actionTypes.DISABLE_SUCCESS;
  payload: IUser;
}
interface IDisableFailAction {
  type: typeof actionTypes.DISABLE_FAIL;
  payload: {
    user: IUser;
    error: IApiError;
  };
}
interface IResetDisableSuccess {
  type: typeof actionTypes.RESET_DISABLE_SUCCESS;
}

interface IViewFromRouteAction {
  type: typeof actionTypes.VIEW_FROM_ROUTE;
  payload: IUser;
}
interface IViewFromDbRequestAction {
  type: typeof actionTypes.VIEW_FROM_DB_REQUEST;
}
interface IViewFromDbSuccessAction {
  type: typeof actionTypes.VIEW_FROM_DB_SUCCESS;
  payload: {userId: string};
}
interface IViewFromDbFailAction {
  type: typeof actionTypes.VIEW_FROM_DB_FAIL;
  payload: IApiError;
}
interface IViewFromDbResetUiErrorAction {
  type: typeof actionTypes.VIEW_FROM_DB_RESET_UI_ERROR;
}
interface IViewResetAction {
  type: typeof actionTypes.VIEW_RESET;
}

interface IUploadBulkFileRequestAction {
  type: typeof actionTypes.UPLOAD_BULK_FILE_REQUEST;
}
interface IUploadBulkFileSuccessAction {
  type: typeof actionTypes.UPLOAD_BULK_FILE_SUCCESS;
  payload: {report: Array<{[Key: string]: string}>; filePath: string};
}
interface IUploadBulkFileFailAction {
  type: typeof actionTypes.UPLOAD_BULK_FILE_FAIL;
  payload: {error: IApiError};
}

interface IResetFileState {
  type: typeof actionTypes.RESET_FILE_STATE;
}

interface IResetNewUserState {
  type: typeof actionTypes.RESET_NEW_USER_STATE;
}

interface IAcceptBulkFileRequestAction {
  type: typeof actionTypes.ACCEPT_BULK_FILE_REQUEST;
}
interface IAcceptBulkFileSuccessAction {
  type: typeof actionTypes.ACCEPT_BULK_FILE_SUCCESS;
  payload: {report: Array<{[Key: string]: string}>};
}
interface IAcceptBulkFileFailAction {
  type: typeof actionTypes.ACCEPT_BULK_FILE_FAIL;
  payload: {error: IApiError};
}

interface IUploadBulkFileRequestAction {
  type: typeof actionTypes.UPLOAD_BULK_FILE_REQUEST;
}
interface IUploadBulkFileSuccessAction {
  type: typeof actionTypes.UPLOAD_BULK_FILE_SUCCESS;
  payload: {report: Array<{[Key: string]: string}>; filePath: string};
}
interface IUploadBulkFileFailAction {
  type: typeof actionTypes.UPLOAD_BULK_FILE_FAIL;
  payload: {error: IApiError};
}

interface IResetFileState {
  type: typeof actionTypes.RESET_FILE_STATE;
}

interface IAcceptBulkFileRequestAction {
  type: typeof actionTypes.ACCEPT_BULK_FILE_REQUEST;
}
interface IAcceptBulkFileSuccessAction {
  type: typeof actionTypes.ACCEPT_BULK_FILE_SUCCESS;
  payload: {report: Array<{[Key: string]: string}>};
}
interface IAcceptBulkFileFailAction {
  type: typeof actionTypes.ACCEPT_BULK_FILE_FAIL;
  payload: {error: IApiError};
}

export const actions = {
  mergeItems: (users: IUsersEntities): IMergeItemsAction => ({
    payload: users,
    type: actionTypes.MERGE_ITEMS,
  }),

  setIsPending: (
    thunkActionsName: ThunkActionsNames,
    id?: string
  ): ISetIsPending => ({
    payload: {thunkActionsName, id},
    type: actionTypes.SET_IS_PENDING,
  }),
  setIsSuccess: (
    thunkActionsName: ThunkActionsNames,
    id?: string
  ): ISetIsSuccess => ({
    payload: {thunkActionsName, id},
    type: actionTypes.SET_IS_SUCCESS,
  }),
  setIsFail: (
    thunkActionsName: ThunkActionsNames,
    error: IApiError,
    id?: string
  ): ISetIsFail => ({
    payload: {thunkActionsName, id},
    error,
    type: actionTypes.SET_IS_FAIL,
  }),
  resetState: (
    thunkActionsName: ThunkActionsNames,
    id?: string
  ): IResetState => ({
    payload: {thunkActionsName, id},
    type: actionTypes.RESET_STATE,
  }),

  create: (user: Partial<IUserEntity>) => (dispatch: Dispatch<actions>) => {
    dispatch(actions.createRequest());

    const birthday =
      user.birthday && moment(user.birthday, "DD/MM/YYYY").format("YYYY-MM-DD");
    const dateRui =
      user.dateRui && moment(user.dateRui, "DD/MM/YYYY").format("YYYY-MM-DD");
    const dateRuiCompany =
      user.dateRuiCompany &&
      moment(user.dateRuiCompany, "DD/MM/YYYY").format("YYYY-MM-DD");

    return service
      .create({...user, birthday, dateRui, dateRuiCompany})
      .then((createdUser) => {
        const {
          result,
          entities: {users},
        } = normalizeUser(createdUser);
        batch(() => {
          dispatch(actions.mergeItems(users));
          dispatch(actions.createSuccess(result));
        });
      })
      .catch((error) => {
        dispatch(actions.createFail(error, error.userId));
      });
  },
  createFail: (error: IApiError, userId: number): ICreateFailAction => ({
    payload: {error, userId},
    type: actionTypes.CREATE_FAIL,
  }),
  createRequest: (): ICreateRequestAction => ({
    type: actionTypes.CREATE_REQUEST,
  }),
  createSuccess: (userId: string): ICreateSuccessAction => ({
    payload: {userId},
    type: actionTypes.CREATE_SUCCESS,
  }),

  list: () => (dispatch: Dispatch<actions>) => {
    dispatch(actions.listRequest());
    return service
      .list()
      .then((usersList) => {
        const {
          result,
          entities: {users},
        } = normalizeUser(usersList);
        batch(() => {
          dispatch(actions.mergeItems(users));
          dispatch(actions.listSuccess(result));
        });
      })
      .catch((error) => {
        console.error(error); // tslint:disable-line
        dispatch(actions.listFail(error));
      });
  },
  listFail: (error: IApiError): IListFailAction => ({
    payload: error,
    type: actionTypes.LIST_FAIL,
  }),
  listRequest: (): IListRequestAction => ({
    type: actionTypes.LIST_REQUEST,
  }),
  listSuccess: (users: string[]): IListSuccessAction => ({
    payload: users,
    type: actionTypes.LIST_SUCCESS,
  }),

  update:
    (id: string, user: Partial<IUserEntity>) =>
    (dispatch: Dispatch<actions>) => {
      dispatch(actions.updateRequest());

      const birthday =
        user.birthday &&
        moment(user.birthday, "DD/MM/YYYY").format("YYYY-MM-DD");
      const dateRui =
        user.dateRui && moment(user.dateRui, "DD/MM/YYYY").format("YYYY-MM-DD");
      const dateRuiCompany =
        user.dateRuiCompany &&
        moment(user.dateRuiCompany, "DD/MM/YYYY").format("YYYY-MM-DD");

      const dateCreated =
        user.dateCreated &&
        moment(user.dateCreated, "DD/MM/YYYY").format("YYYY-MM-DD");
      const lastLogin =
        user.lastLogin &&
        moment(user.lastLogin, "DD/MM/YYYY").format("YYYY-MM-DD");

      return service
        .update(id, {
          ...user,
          birthday,
          dateRui,
          dateCreated,
          lastLogin,
          dateRuiCompany,
        })
        .then((updatedUser) => {
          const {
            result,
            entities: {users},
          } = normalizeUser(updatedUser);
          batch(() => {
            dispatch(actions.mergeItems(users));

            dispatch(actions.updateSuccess(result));
          });
        })
        .catch((error) => {
          dispatch(actions.updateFail(error));
        });
    },
  updateFail: (error: IApiError): IUpdateFailAction => ({
    payload: error,
    type: actionTypes.UPDATE_FAIL,
  }),
  updateRequest: (): IUpdateRequestAction => ({
    type: actionTypes.UPDATE_REQUEST,
  }),
  updateSuccess: (userId: string): IUpdateSuccessAction => ({
    payload: {userId},
    type: actionTypes.UPDATE_SUCCESS,
  }),

  updateStatus:
    (userId: IUserEntity["id"], status: IUserEntity["status"]) =>
    (dispatch: Dispatch<actions>) => {
      actions
        .update(userId, {status})(dispatch)
        .then(() => actions.list()(dispatch));
    },

  resetPasswordContractor:
    (contractorId: string) => (dispatch: Dispatch<actions>) => {
      dispatch(actions.resetPasswordContractorRequest());

      return service
        .resetPasswordContractor(contractorId)
        .then((updatedUser) => {
          dispatch(actions.resetPasswordContractorSuccess());
        })
        .catch((error) => {
          dispatch(actions.resetPasswordContractorFail(error));
        });
    },
  resetPasswordContractorFail: (
    error: IApiError
  ): IResetPasswordContractorFailAction => ({
    payload: error,
    type: actionTypes.RESET_PASSWORD_CONTRACTOR_FAIL,
  }),
  resetPasswordContractorRequest:
    (): IResetPasswordContractorRequestAction => ({
      type: actionTypes.RESET_PASSWORD_CONTRACTOR_REQUEST,
    }),
  resetPasswordContractorSuccess:
    (): IResetPasswordContractorSuccessAction => ({
      type: actionTypes.RESET_PASSWORD_CONTRACTOR_SUCCESS,
    }),

  delete: (userId: string) => (dispatch: Dispatch<actions>) => {
    dispatch(actions.deleteRequest(userId));
    return service
      .update(userId, {
        status: "-1",
      })
      .then(() => {
        dispatch(actions.deleteSuccess(userId));
      })
      .catch((error) => {
        dispatch(actions.deleteFail(error, userId));
      });
  },
  deleteFail: (error: IApiError, userId: string): IDeleteFailAction => ({
    payload: {error, userId},
    type: actionTypes.DELETE_FAIL,
  }),
  deleteRequest: (userId: string): IDeleteRequestAction => ({
    payload: {userId},
    type: actionTypes.DELETE_REQUEST,
  }),
  deleteSuccess: (userId: string): IDeleteSuccessAction => ({
    payload: {userId},
    type: actionTypes.DELETE_SUCCESS,
  }),
  resetDeleteSuccess: (): IResetDeleteSuccess => ({
    type: actionTypes.RESET_DELETE_SUCCESS,
  }),

  reset: (): IResetAction => {
    return {
      type: actionTypes.RESET,
    };
  },

  fromRoute: (userJson: string): IFromRouteAction => {
    const user = JSON.parse(userJson);
    // cap.closed = cap.closed === "1";
    user.data = user.jsonData ? JSON.parse(user.jsonData) : {};
    // cap.contractor = {
    //   ...cap.contractor,
    //   birthday: moment(cap.contractor.birthday)
    // };
    return {
      payload: user,
      type: actionTypes.FROM_ROUTE,
    };
  },

  fromDb: (id: string) => (dispatch: Dispatch<actions>) => {
    dispatch(actions.fromDbRequest());
    return service
      .read(id)
      .then((readUser) => {
        const {
          result,
          entities: {users},
        } = normalizeUser(readUser);
        batch(() => {
          dispatch(actions.mergeItems(users));

          dispatch(actions.fromDbSuccess(result));
        });
      })
      .catch((error) => {
        dispatch(actions.fromDbFail(error));
      });
  },
  fromDbFail: (error: IApiError): IFromDbFailAction => ({
    payload: error,
    type: actionTypes.FROM_DB_FAIL,
  }),
  fromDbRequest: (): IFromDbRequestAction => ({
    type: actionTypes.FROM_DB_REQUEST,
  }),
  fromDbResetUiError: (): IFromDbResetUiErrorAction => {
    return {
      type: actionTypes.FROM_DB_RESET_UI_ERROR,
    };
  },
  fromDbSuccess: (userId: string): IFromDbSuccessAction => {
    return {
      payload: {userId},
      type: actionTypes.FROM_DB_SUCCESS,
    };
  },
  // Gestione del cap in visualizzazione
  viewFromRoute: (userJson: string): IViewFromRouteAction => {
    const user = JSON.parse(userJson);
    return {
      payload: user,
      type: actionTypes.VIEW_FROM_ROUTE,
    };
  },

  viewFromDb: (userId: string) => (dispatch: Dispatch<actions>) => {
    dispatch(actions.viewFromDbRequest());
    return service
      .read(userId)
      .then((readUser) => {
        console.log("ddd", readUser);
        const {
          result,
          entities: {users},
        } = normalizeUser(readUser);
        batch(() => {
          dispatch(actions.mergeItems(users));

          dispatch(actions.viewFromDbSuccess(result));
        });
      })
      .catch((error) => {
        console.log({error}); // tslint:disable-line
        dispatch(actions.viewFromDbFail(error));
      });
  },
  viewFromDbFail: (error: IApiError): IViewFromDbFailAction => ({
    payload: error,
    type: actionTypes.VIEW_FROM_DB_FAIL,
  }),
  viewFromDbRequest: (): IViewFromDbRequestAction => ({
    type: actionTypes.VIEW_FROM_DB_REQUEST,
  }),
  viewFromDbSuccess: (userId: string): IViewFromDbSuccessAction => {
    return {
      payload: {userId},
      type: actionTypes.VIEW_FROM_DB_SUCCESS,
    };
  },

  viewFromDbResetUiError: (): IViewFromDbResetUiErrorAction => {
    return {
      type: actionTypes.VIEW_FROM_DB_RESET_UI_ERROR,
    };
  },

  viewReset: (): IViewResetAction => {
    return {
      type: actionTypes.VIEW_RESET,
    };
  },

  uploadBulkFile:
    (file: File, action: "create" | "update" | "delete") =>
    (dispatch: Dispatch<actions>) => {
      dispatch(actions.uploadBulkFileRequest());
      return service
        .uploadBulkFile(file, action)
        .then((responseJson) => {
          dispatch(
            actions.uploadBulkFileSuccess(
              responseJson.usersData,
              responseJson.filePath
            )
          );
        })
        .catch((error) => {
          dispatch(actions.uploadBulkFileFail(error));
        });
    },
  uploadBulkFileFail: (error: IApiError): IUploadBulkFileFailAction => ({
    payload: {error},
    type: actionTypes.UPLOAD_BULK_FILE_FAIL,
  }),
  uploadBulkFileRequest: (): IUploadBulkFileRequestAction => ({
    type: actionTypes.UPLOAD_BULK_FILE_REQUEST,
  }),
  uploadBulkFileSuccess: (
    report: Array<{[Key: string]: string}>,
    filePath: string
  ): IUploadBulkFileSuccessAction => ({
    payload: {report, filePath},
    type: actionTypes.UPLOAD_BULK_FILE_SUCCESS,
  }),

  resetFileState: (): IResetFileState => ({
    type: actionTypes.RESET_FILE_STATE,
  }),

  resetNewUserState: (): IResetNewUserState => ({
    type: actionTypes.RESET_NEW_USER_STATE,
  }),

  acceptBulkFile:
    (filePath: string, action: "create" | "update" | "delete") =>
    (dispatch: Dispatch<actions>) => {
      dispatch(actions.acceptBulkFileRequest());
      return service
        .acceptBulkFile(filePath, action)
        .then((responseJson) => {
          batch(() => {
            dispatch(actions.resetFileState());
            dispatch(actions.acceptBulkFileSuccess(responseJson.usersData));
          });
        })
        .catch((error) => {
          dispatch(actions.acceptBulkFileFail(error));
        });
    },
  acceptBulkFileFail: (error: IApiError): IAcceptBulkFileFailAction => ({
    payload: {error},
    type: actionTypes.ACCEPT_BULK_FILE_FAIL,
  }),
  acceptBulkFileRequest: (): IAcceptBulkFileRequestAction => ({
    type: actionTypes.ACCEPT_BULK_FILE_REQUEST,
  }),
  acceptBulkFileSuccess: (
    report: Array<{[Key: string]: string}>
  ): IAcceptBulkFileSuccessAction => ({
    payload: {report},
    type: actionTypes.ACCEPT_BULK_FILE_SUCCESS,
  }),

  privacySubscriptionNotification:
    (capId: string, update?: boolean) => (dispatch: Dispatch<actions>) => {
      dispatch(actions.setIsPending("privacySubscriptionNotification"));

      return service
        .privacySubscriptionNotification(capId, update)
        .then(() => {
          dispatch(actions.setIsSuccess("privacySubscriptionNotification"));
        })
        .catch((error) => {
          dispatch(actions.setIsFail("privacySubscriptionNotification", error));
        });
    },
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type actions =
  | IMergeItemsAction
  | ISetIsPending
  | ISetIsSuccess
  | ISetIsFail
  | IResetState
  | IListRequestAction
  | IListSuccessAction
  | IListFailAction
  | ICreateRequestAction
  | ICreateSuccessAction
  | ICreateFailAction
  | IUpdateRequestAction
  | IUpdateSuccessAction
  | IUpdateFailAction
  | IUpdateStatusRequestAction
  | IUpdateStatusSuccessAction
  | IUpdateStatusFailAction
  | IResetPasswordContractorRequestAction
  | IResetPasswordContractorSuccessAction
  | IResetPasswordContractorFailAction
  | IDeleteRequestAction
  | IDeleteSuccessAction
  | IDeleteFailAction
  | IResetDeleteSuccess
  | IEnableRequestAction
  | IEnableSuccessAction
  | IEnableFailAction
  | IResetEnableSuccess
  | IDisableRequestAction
  | IDisableSuccessAction
  | IDisableFailAction
  | IResetDisableSuccess
  | IFromRouteAction
  | IFromDbRequestAction
  | IFromDbSuccessAction
  | IFromDbFailAction
  | IFromDbResetUiErrorAction
  | IResetAction
  | IViewFromRouteAction
  | IViewFromDbFailAction
  | IViewFromDbRequestAction
  | IViewFromDbSuccessAction
  | IViewResetAction
  | IViewFromDbResetUiErrorAction
  | IUploadBulkFileFailAction
  | IUploadBulkFileRequestAction
  | IUploadBulkFileSuccessAction
  | IResetFileState
  | IResetNewUserState
  | IAcceptBulkFileFailAction
  | IAcceptBulkFileRequestAction
  | IAcceptBulkFileSuccessAction;
