import {DeepReadonly} from "utility-types/dist/mapped-types";
import {IAppThunkActionStates} from "../types/thunk";
import {
  actions as userActions,
  actionTypes as userActionTypes,
} from "../User/actions";
import {actions, ActionTypes} from "./actions";
import {IProposalProductDocumentsDB, IProposalsEntities} from "./types";

export type ThunkActionsNames =
  | "create"
  | "get"
  | "list"
  | "update"
  | "duplicate"
  | "sendDarta"
  | "uploadDocument"
  | "deleteDocument"
  | "eSignDocument"
  | "proposalProductDocumentsList";

export type IState = DeepReadonly<{
  entities: IProposalsEntities;
  thunkActionsStates: {
    [key in ThunkActionsNames]?: {[key: string]: IAppThunkActionStates};
  };

  isESignsManagementModalOpen?: boolean;
  fileName?: string;

  chosenCap?: string;
  itemsIds: string[];
  lastCreatedId?: string;
  duplicatedId?: string;

  proposalProductDocuments: IProposalProductDocumentsDB[];
}>;

const proposalsInitialState = {
  entities: {},
  thunkActionsStates: {},

  itemsIds: [],

  proposalProductDocuments: [],
};

export const reducer = (
  state: IState = proposalsInitialState,
  action: actions | userActions
): IState => {
  switch (action.type) {
    case userActionTypes.LOGOUT_SUCCESS:
      return {
        ...proposalsInitialState,
      };

    case ActionTypes.MERGE_ITEMS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload.proposals,
        },
      };

    case ActionTypes.SET_IS_PENDING:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          [action.payload.thunkActionsName]: {
            ...state.thunkActionsStates[action.payload.thunkActionsName],
            [action.payload.id ?? "DEFAULT"]: {
              isPending: true,
              isSuccess: false,
              isFail: false,
              error: undefined,
            },
          },
        },
      };
    case ActionTypes.SET_IS_SUCCESS:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          [action.payload.thunkActionsName]: {
            ...state.thunkActionsStates[action.payload.thunkActionsName],
            [action.payload.id ?? "DEFAULT"]: {
              isPending: false,
              isSuccess: true,
              isFail: false,
              error: undefined,
            },
          },
        },
      };
    case ActionTypes.SET_IS_FAIL:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          [action.payload.thunkActionsName]: {
            ...state.thunkActionsStates[action.payload.thunkActionsName],
            [action.payload.id ?? "DEFAULT"]: {
              isPending: false,
              isSuccess: false,
              isFail: true,
              error:
                action.error.message ?? "Errore sconosciuto, riprova più tardi",
            },
          },
        },
      };
    case ActionTypes.RESET_STATE:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          [action.payload.thunkActionsName]: {
            ...state.thunkActionsStates[action.payload.thunkActionsName],
            [action.payload.id ?? "DEFAULT"]: {
              isPending: false,
              isSuccess: false,
              isFail: false,
              error: undefined,
            },
          },
        },
      };

    case ActionTypes.CREATED:
      return {
        ...state,
        lastCreatedId: action.payload.proposalId,
      };

    case ActionTypes.GOT_LIST:
      return {
        ...state,
        itemsIds: action.payload.proposalsIds,
      };

    case ActionTypes.DUPLICATED:
      return {
        ...state,
        duplicatedId: action.payload.proposalsIds.newProposalId,
      };

    case ActionTypes.OPEN_E_SIGN_MANAGEMENT_MODAL:
      return {
        ...state,
        isESignsManagementModalOpen: true,
        fileName: action.payload.fileName,
      };
    case ActionTypes.CLOSE_E_SIGN_MANAGEMENT_MODAL:
      return {
        ...state,
        isESignsManagementModalOpen: false,
        fileName: undefined,
      };

    case ActionTypes.CHOOSE_CAP:
      return {
        ...state,
        chosenCap: action.payload.capId,
      };

    case ActionTypes.GOT_PROPOSAL_PRODUCT_DOCUMENTS_LIST:
      return {
        ...state,
        proposalProductDocuments: action.payload.proposalProductDocuments,
      };

    default:
      return state;
  }
};

export default reducer;
