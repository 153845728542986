import {configureStore} from "@reduxjs/toolkit";
import {Action} from "redux";
import {ThunkAction} from "redux-thunk";
import {yn} from "../helpers/yn";
import {actions} from "../User/actions";
import {getLSUser} from "../User/getLSUser";
import getCustomMiddleware from "./getCustomMiddleware";
import {IRootState, rootReducer} from "./reducers";

export type IAppThunkAction = ThunkAction<
  void,
  IRootState,
  unknown,
  Action<string>
>;

export type IAppDispatch = typeof store.dispatch;

const initUser = getLSUser();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({immutableCheck: {ignoredPaths: ["form"]}}).concat(
      getCustomMiddleware()
    ),
  preloadedState: {user: initUser},
  devTools: yn(process.env.REACT_APP_ENABLE_DEV_TOOLS),
});

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
}

actions.ping()(store.dispatch);

export default store;
