import createCachedSelector from "re-reselect";
import {IRootState} from "../redux/reducers";
import {ICompaniesName, IPermissionName} from "./types";

export const selectLoggedUser = (state: IRootState) => state.user.user;
export const getUserPermissions = (state: IRootState) => state.user.permissions;
export const getUserCompanies = (state: IRootState) => state.user.userCompanies;

export const getHasPermission = createCachedSelector(
  [
    getUserPermissions,
    (_: IRootState, permission: IPermissionName) => permission,
  ],
  (userPermissions: IPermissionName[], permission: IPermissionName) =>
    userPermissions.includes(permission)
)((state: IRootState, permission: IPermissionName) => permission);

export const getHasCompany = createCachedSelector(
  [getUserCompanies, (_: IRootState, company: ICompaniesName) => company],
  (userCompanies: ICompaniesName[], company: ICompaniesName) =>
    userCompanies.includes(company)
)((state: IRootState, company: ICompaniesName) => company);

export const getPrivacy = (state: IRootState) => state.user.privacy;
export const getPrivacySubscription = (state: IRootState) =>
  state.user.privacySubscription;
