import {
  ActionCreatorWithPreparedPayload,
  AnyAction,
  AsyncThunk,
  AsyncThunkPayloadCreator,
  createAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import {GenericThunkArg} from "./createThunkStatesSlice";

interface ResetAction {
  type: string;
  payload: undefined;
  meta: {
    arg: GenericThunkArg;
  };
}

export const isReset = (action: AnyAction): action is ResetAction => {
  return action.type.endsWith("/reset");
};

type AsyncThunkResetActionCreator = ActionCreatorWithPreparedPayload<
  [{thunkId: string} | void],
  undefined,
  string,
  never,
  {
    arg: {thunkId: string} | void;
    requestStatus: "reset";
  }
>;

type AsyncThunkWithReset<Returned, ThunkArg, ThunkApiConfig> = AsyncThunk<
  Returned,
  ThunkArg,
  ThunkApiConfig
> & {
  reset: AsyncThunkResetActionCreator;
};

export const createAsyncThunkAndReset = <Returned, ThunkArg, ThunkApiConfig>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>
) => {
  const thunk = createAsyncThunk(
    typePrefix,
    payloadCreator
  ) as AsyncThunkWithReset<Returned, ThunkArg, ThunkApiConfig>;

  thunk.reset = createAction(
    typePrefix + "/reset",
    (arg: {thunkId: string} | void) => {
      return {
        payload: undefined,
        meta: {arg, requestStatus: "reset" as const},
      };
    }
  );

  return thunk;
};
