import moment from "moment";
import {apiService} from "../App/service";
import {IUploadIdFormData} from "../Caps/NewPage/ClosingSection/IdentificationDrawer/UploadIdForm";
import {IUserDB, IUserEntity} from "./types";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}users`;

const usersService = {
  create(user: Partial<IUserEntity>) {
    if (!!user.birthday) {
      user.birthday = moment
        .utc(user.birthday, "DD-MM-YYYY")
        .format("YYYY-MM-DD");
    }
    return apiService
      .post(apiUrl, user)
      .then((responseJson) => responseJson.user as IUserDB);
  },

  read(id: string) {
    return apiService
      .get(`${apiUrl}/${id}`)
      .then((responseJson) => responseJson.user as IUserDB);
  },

  list() {
    return apiService
      .get(apiUrl)
      .then((responseJson) => responseJson.users as IUserDB[]);
  },

  update(id: string, user: Partial<IUserEntity>) {
    return apiService
      .put(`${apiUrl}/${id}`, user)
      .then((responseJson) => responseJson.user as IUserDB);
  },

  uploadId(id: string, values: IUploadIdFormData) {
    return apiService
      .post(`${apiUrl}/${id}/upload-id`, values)
      .then((responseJson) => responseJson.user as IUserDB);
  },

  resetPasswordContractor(
    id: string
  ): Promise<{status: "ok" | "ko"; message: string}> {
    return apiService.post(`${apiUrl}/${id}/re-send-credential-contractor`, {});
  },

  delete(id: string) {
    return apiService.delete(`${apiUrl}/${id}`);
  },

  uploadBulkFile(file: File, action: "create" | "update" | "delete") {
    return apiService.post(`${apiUrl}/${action}-bulk-report`, {file});
  },

  acceptBulkFile(filePath: string, action: "create" | "update" | "delete") {
    return apiService.post(`${apiUrl}/${action}-bulk`, {filePath});
  },

  privacySubscriptionNotification(capId: string, update: boolean = false) {
    return apiService.post(`${apiUrl}/privacy-subscription-notification`, {
      capId,
      update,
    });
  },
};

export default usersService;
