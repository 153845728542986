import React, {ReactNode, VFC} from "react";
import {useLocation} from "react-router";
import {Navigate, Route} from "react-router-dom";
import IRoutes from "../types/IRoutes";
import {ICompaniesName, IPermissionName} from "../User/types";
import NotAuthorizedPage from "../User/NotAuthorizedPage";

const checkPermission = (permissions: string[], permission: string) => {
  if (!permissions.includes) {
    console.log("Errore include", {permissions, permission}); // tslint:disable-line
    return false;
  }

  return permission.split("||").reduce((orsAccumulator, ors) => {
    return (
      ors.split("&&").reduce((andsAccumulator, ands) => {
        return permissions.includes(ands) && andsAccumulator;
      }, true) || orsAccumulator
    );
  }, false);
};

const checkCompany = (companies: string[], company: string) => {
  if (!companies.includes) {
    console.log("Errore include", {
      companies,
      company,
    }); // tslint:disable-line
    return false;
  }

  return company.split("||").reduce((orsAccumulator, ors) => {
    return (
      ors.split("&&").reduce((andsAccumulator, ands) => {
        return companies.includes(ands) && andsAccumulator;
      }, true) || orsAccumulator
    );
  }, false);
};

interface IMakeRoutes {
  routes: IRoutes;
  companies: ICompaniesName[];
  permissions: IPermissionName[];
  isLogged: boolean;
}

// IDEA: Invece che dichiarare companies and permission per ogni route (cosa che
//  rende difficile creare route con controlli particolare ad esempio associare
//  permessi a compagnie), dichiariamo una funzione che prende in ingresso
//  permessi e compagnie e ci ritorna un boolean:
//  ({companies, permissions}) =>  boolean;

interface JFRouteProps {
  children: ReactNode;
  companies: ICompaniesName[];
  company?: string;
  isLogged: boolean;
  permission?: string;
  permissions: IPermissionName[];
}

const JFRoute: VFC<JFRouteProps> = ({
  children,
  companies,
  permissions,
  isLogged,
  permission,
  company,
}) => {
  const hasPermissions =
    !permission || checkPermission(permissions, permission);
  const hasCompany = !company || checkCompany(companies, company);
  const location = useLocation();

  if (hasPermissions && hasCompany) {
    // User can access the route
    return <>{children}</>;
  }

  if (isLogged) {
    // User is authenticated but cannot access the route
    return <NotAuthorizedPage />;
  }

  // User is not authenticated
  return <Navigate to={`/login?next=${location.pathname}`} />;
};

const makeRoutes = ({
  routes,
  companies,
  permissions,
  isLogged,
}: IMakeRoutes) => {
  return routes.map((routeProps) => {
    const {children, company, name, permission, ...byPass} = routeProps;

    return (
      <Route
        key={name}
        element={
          <JFRoute
            children={children}
            companies={companies}
            permissions={permissions}
            isLogged={isLogged}
            company={company}
            permission={permission}
          />
        }
        {...byPass}
      />
    );
  });
};

export default makeRoutes;
