import last from "lodash/last";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {matchPath, useLocation} from "react-router-dom";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {actions as eSignActions} from "../ESign/actions";
import {PDFTypes} from "../ESign/types";
import {IconPerson, IconSpinner} from "../Icons";
import {IRootState} from "../redux/reducers";
import {IPrivacyHistory, IUser, IUserDB} from "../Users/types";
import {actions} from "./actions";
import PrivacyForm, {IPrivacyFormData} from "./PrivacyForm";
import "./Profile.scss";
import {getPrivacy, selectLoggedUser, getUserPermissions} from "./selectors";
import {IPermissionName, IPrivacy} from "./types";

interface IStateProps {
  user: IUser | null;
  isPrivacyFail?: boolean;
  isPrivacyOpen?: boolean;
  privacy?: IPrivacy;
  privacyError?: string;
  permissions: IPermissionName[];
}

interface IDispatchProps {
  privacySubmit: (values: IPrivacyFormData) => Promise<void>;
  openRequestOTPModal: (userId: string) => void;
  loadPrivacy: () => Promise<void>;
}

const mapStateToProps = (state: IRootState) => ({
  isPrivacyFail: state.user.isPrivacyFail,
  isPrivacyOpen: state.user.isPrivacyOpen,
  permissions: getUserPermissions(state),
  privacy: getPrivacy(state),
  privacyError: state.user.privacyError,
  user: selectLoggedUser(state),
});

const mapDispatchToProps = {
  openRequestOTPModal: (userId: string) => {
    return eSignActions.openModal(PDFTypes.privacy, userId);
  },
  privacySubmit: (values: IPrivacyFormData) => {
    return actions.privacy(values);
  },
  loadPrivacy: () => {
    return actions.loadPrivacy();
  },
};

type IProps = IStateProps & IDispatchProps;

// TODO: da sistemare
const PrivacyModal: React.FC<IProps> = (props) => {
  const location = useLocation();
  const isProfilePathActive = !!matchPath(location.pathname, "/user/profile");

  // Se sono contractor esigo che ci sia la firma
  const openAsContractor =
    props.user &&
    props.permissions.includes("cap.contractor-read") &&
    !(props.user as unknown as IUserDB).lastPrivacyEsignId;
  // Se non sono contractor va bene solo privacy
  const openAsNotContractor =
    props.user &&
    !props.permissions.includes("cap.contractor-read") &&
    !(props.user as unknown as IUserDB).jsonPrivacy;

  useEffect(() => {
    props.loadPrivacy().then();
    // eslint-disable-next-line
  }, []);

  const userPrivacy = (props.user as unknown as IUserDB)?.jsonPrivacy;
  const privacyHistory = userPrivacy
    ? (JSON.parse(userPrivacy) as IPrivacyHistory)
    : undefined;
  const lastPrivacyHistory =
    privacyHistory && Array.isArray(privacyHistory)
      ? last(privacyHistory)
      : privacyHistory;
  const privacyInitialValues =
    lastPrivacyHistory === "true" ? undefined : lastPrivacyHistory?.values;

  const isUpdating =
    lastPrivacyHistory === "true" ||
    !!(
      lastPrivacyHistory &&
      props.privacy &&
      parseInt(props.privacy.version, 10) >
        parseInt(lastPrivacyHistory.version, 10)
    );

  return (
    <Modal
      isOpen={
        !isProfilePathActive &&
        (openAsContractor || openAsNotContractor || props.isPrivacyOpen)
      }
      backdrop="static"
      keyboard={false}
      className="modal-form"
    >
      <ModalHeader>
        <IconPerson size={3} className="icon-border-2 rounded-circle p-2" />
        Accettazione del consenso
      </ModalHeader>
      <ModalBody>
        {props.privacy ? (
          <PrivacyForm
            {...props}
            isUpdating={isUpdating}
            form="privacyFormESign"
            initialValues={privacyInitialValues}
            privacy={props.privacy}
          />
        ) : (
          <div className="text-center">
            <IconSpinner className="icon-spin mr-2 my-4" />
            Caricamento privacy in corso...
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyModal);
