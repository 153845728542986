import * as Sentry from "@sentry/browser";
import moment from "moment";
import "whatwg-fetch";
import errors, {ErrorCodes} from "../helpers/errors";
import store from "../redux/store";
import IApiError from "../types/IApiError";
import {actions} from "../User/actions";

const handleResponse = (response: Response) =>
  response
    .clone()
    .json()
    .then((responseJson) => {
      if (responseJson.status !== "ok") {
        if (responseJson.error && responseJson.error.code === 401) {
          // Se ho un errore di sessione elimino il localstorage
          store.dispatch(actions.logoutSuccess());
        }
        return Promise.reject(responseJson.error);
      }
      return responseJson;
    })
    .catch((error) => {
      handleCommonErrors(error, response);
    });

const handleCommonErrors = (error: Error | IApiError, response?: Response) => {
  if (error.message === "Failed to fetch") {
    throw errors[ErrorCodes.FAILED_TO_FETCH];
  } else {
    if (error.message === "Unexpected token < in JSON at position 0") {
      error.message = errors[ErrorCodes.GENERIC_SERVER_ERROR].message;
    }
    if (process.env.NODE_ENV !== "development") {
      if (!(error as any)?.code && response) {
        response
          .clone()
          .text()
          .then((serverTextResponse) => {
            Sentry.withScope((scope) => {
              scope.setExtra(
                "Server response",
                serverTextResponse ?? "No global response"
              );
              // tslint:disable-next-line
              Sentry.captureException(error);
            });
          });
      }
    }
    throw error;
  }
};

const fixMoment = (data: {[key: string]: any}) => {
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof moment) {
      data[key] = data[key].format();
    }
  });
  return data;
};

export const apiService = {
  delete(url: string) {
    return fetch(url, {
      credentials: "include",
      headers: new Headers({
        "Access-Control-Allow-Origin": "http://localhost:3000",
      }),
      method: "delete",
    })
      .then(handleResponse)
      .catch(handleCommonErrors);
  },
  get(url: string, data: {[key: string]: any} = {}) {
    const fixedData = fixMoment(data);
    const queryString = Object.keys(fixedData)
      .filter((key) => fixedData[key] !== undefined)
      .map((key) => `${key}=${fixedData[key]}`)
      .join("&");
    return fetch(`${url}${queryString.length > 0 ? "?" + queryString : ""}`, {
      credentials: "include",
      headers: new Headers({
        "Access-Control-Allow-Origin": "http://localhost:3000",
      }),
      method: "get",
    })
      .then(handleResponse)
      .catch(handleCommonErrors);
  },
  post(url: string, data: {[key: string]: any}) {
    const fixedData = fixMoment(data);
    const formData = new FormData();
    Object.keys(fixedData)
      .filter((key) => fixedData[key] !== undefined)
      .forEach((key) => {
        formData.append(key, fixedData[key]);
      });
    return fetch(url, {
      body: formData,
      credentials: "include",
      headers: new Headers({
        "Access-Control-Allow-Origin": "http://localhost:3000",
      }),
      method: "post",
    })
      .then(handleResponse)
      .catch(handleCommonErrors);
  },
  put(url: string, data: {[key: string]: any}) {
    return fetch(url, {
      body: JSON.stringify(fixMoment(data)),
      credentials: "include",
      headers: new Headers({
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Content-Type": "application/json",
      }),
      method: "put",
    })
      .then(handleResponse)
      .catch(handleCommonErrors);
  },
};
