import React, {FC, VFC} from "react";
import {useSelector} from "react-redux";
import {Navigate, Route, Routes} from "react-router-dom";
import beneProposalsRoutes from "../BeneProposals/routes";
import capsRoutes from "../Caps/routes";
import elementaryClassesRoutes from "../ElementaryClasses/routes";
import makeRoutes from "../helpers/makeRoutes";
import proposalsRoutes from "../Proposals/routes";
import recommendationsRoutes from "../Recommendation/routes";
import userRoutes from "../User/routes";
import {
  getUserCompanies,
  getUserPermissions,
  selectLoggedUser,
} from "../User/selectors";
import {IPermissionName} from "../User/types";
import usersRoutes from "../Users/routes";
import appRoutes from "./appRoutes";
import {Layout} from "./Layout";
import MaintenancePage from "./MaintenancePage";
import {NoMenuLayout} from "./NoMenuLayout";
import NotFoundPage from "./NotFoundPage";

export const routes = [
  ...appRoutes,
  ...capsRoutes,
  ...userRoutes,
  ...usersRoutes,
  ...recommendationsRoutes,
  ...proposalsRoutes,
  ...beneProposalsRoutes,
  ...elementaryClassesRoutes,
];

interface HomeComponentProps {
  isLogged?: boolean;
  permissions: IPermissionName[];
}

const HomeComponent: VFC<HomeComponentProps> = ({isLogged, permissions}) => {
  if (!isLogged) {
    return <Navigate to="/login" replace />;
  }
  if (
    permissions.includes("cap.read") ||
    permissions.includes("cap.contractor-read")
  ) {
    return <Navigate to="/analisi" replace />;
  }
  if (
    permissions.includes("recommendation.read") ||
    permissions.includes("recommendations.read") ||
    permissions.includes("recommendation.contractor-read")
  ) {
    return <Navigate to="/raccomandazioni" replace />;
  }
  if (permissions.includes("users.manage")) {
    return <Navigate to="/users" replace />;
  }
  return <Navigate to="/user/profile" replace />;
};

const RouterComponent: FC = () => {
  const user = useSelector(selectLoggedUser);
  const companies = useSelector(getUserCompanies);
  const permissions = useSelector(getUserPermissions);

  const maintenanceMode = false; //moment().isAfter(moment("2021-02-23 22:00:00"));
  if (maintenanceMode) {
    return <MaintenancePage />;
  }

  const isLogged = !!user;

  if (!permissions.includes) {
    console.log(
      "Errore include",
      JSON.stringify(permissions, null, 2),
      permissions
    );
  }

  const noMenuLayoutRoutes = routes.filter(
    (route) => route.layout === "noMenu"
  );
  const defaultLayoutRoutes = routes.filter(
    (route) => route.layout === undefined || route.layout === "default"
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <HomeComponent isLogged={isLogged} permissions={permissions} />
        }
      />
      {/*<Route exact path={flatMap(noMenuLayoutRoutes, (route) => route.path)}>*/}
      <Route element={<NoMenuLayout />}>
        {makeRoutes({
          routes: noMenuLayoutRoutes,
          companies,
          permissions,
          isLogged,
        })}
      </Route>
      {/*<Route exact path={flatMap(defaultLayoutRoutes, (route) => route.path)}>*/}
      <Route element={<Layout />}>
        {makeRoutes({
          routes: defaultLayoutRoutes,
          companies,
          permissions,
          isLogged,
        })}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default RouterComponent;
